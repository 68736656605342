import { AppBar, Box, Button, buttonClasses, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';

interface Props {
  $isOpen: boolean;
  $height: number;
}

interface AppBarProps {
  $scrollDirection: 'up' | 'down' | 'inherit';
}

interface NavigationProps {
  $isOpen: boolean;
}

const TRANSITION_DURATION = '.3s';

export const MobileAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== '$scrollDirection',
})<AppBarProps>(({ theme, $scrollDirection }) => ({
  transition: `transform ${TRANSITION_DURATION} ease-in-out`,
  transform: `translateY(${['inherit', 'up'].includes($scrollDirection) ? '0' : '-100%'})`,
  backgroundColor: theme.palette.neutral01[700],
  zIndex: 1001,
}));

export const MenuMobile = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== '$isOpen' && prop !== '$scrollDirection' && prop !== '$height',
})<Props>(({ theme, $isOpen, $height }) => ({
  height: `calc(${$height}px - 58px)`,
  width: '100vw',
  position: 'fixed',
  top: 57,
  right: 0,
  backgroundColor: theme.palette.neutral01[700],
  zIndex: 10,
  transition: `transform ${TRANSITION_DURATION} ease-in, opacity 0s ease-in ${TRANSITION_DURATION}`,
  opacity: 0,
  overflow: 'hidden',
  transform: 'translateY(-100%)',
  willChange: 'transform',

  ...($isOpen && {
    transition: `transform ${TRANSITION_DURATION} ease-out, opacity 0s ease-in`,
    maxHeight: 'calc(100vh - 58px)',
    opacity: 1,
    borderRadius: 0,
    transform: 'translateY(0)',
  }),
}));

export const MenuIcon = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',
  padding: 0,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  },
}));

export const MenuMobileContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(3, 2),
  height: '100%',
  width: '100%',
  color: theme.palette.text.primary,
  overflowY: 'scroll',
}));

export const MenuMobileLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
}));
export const MenuMobileSubLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
}));

export const MenuMobileButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  justifyContent: 'space-between',
  '&:hover': {
    color: theme.palette.text.primary,
    [`& .${buttonClasses.endIcon}`]: {
      fill: theme.palette.global01[400],
    },
  },

  [`& .${buttonClasses.endIcon}`]: {
    transform: 'rotate(0deg)',
    marginRight: '0px',
  },
  overflow: 'visible',
}));

export const SubMenuMobileContent = styled(Container)(({ theme }) => ({
  display: 'grid',
  // gridTemplateColumns: 'repeat(4, 1fr)',
  gap: theme.spacing(3),
  padding: 0,
  justifyItems: 'left',
  alignItems: 'left',
}));

export const SubMenuMobile = styled(Container, {
  shouldForwardProp: (prop) => prop !== '$isOpen',
})<NavigationProps>(({ theme, $isOpen = false }) => ({
  minWidth: '90vw',
  visibility: 'hidden',
  zIndex: 1000,
  transition: 'max-height 0.2s ease-in-out, opacity 0.2s ease-in-out',
  backgroundColor: theme.palette.neutral01[700],
  pointerEvents: 'none',
  overflow: 'hidden',
  // boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.05)',
  textAlign: 'left',
  ...($isOpen && {
    pointerEvents: 'auto',
    visibility: 'visible',
    color: theme.palette.text.primary,
    fill: theme.palette.text.primary,
  }),
}));

export const SubMenuItem = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.text.primary,
  fill: theme.palette.text.primary,

  '&:hover': {
    [`& > *`]: {
      fill: theme.palette.global01[400],
      color: theme.palette.global01[400],
    },
  },
}));
