import { Box, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useLocale } from 'hooks/useLocale';
import { theme } from 'theme';
import { Locale } from 'types/i18n.types';

import { IcoArrow, IcoFlag } from 'components/@icons';

import { DropDownButton, DropDownItem, DropDownMenu } from '.';

interface Props {
  variant?: 'text' | 'default';
  fullWidth: boolean;
}

const LanguageMenu = ({ variant = 'default', fullWidth = false }: Props) => {
  const { locale, updateLocale } = useLocale();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLocaleClick = (newLocale: Locale) => {
    updateLocale(newLocale);
    setAnchorEl(null);
  };

  return (
    <>
      <DropDownButton $variant={variant} onClick={handleClick} $fullWidth={fullWidth}>
        <Box>
          {variant !== 'default' && (
            <IcoFlag
              style={{
                width: '24px',
                height: '24px',
                marginRight: '0px',
              }}
            />
          )}
          <Typography variant={variant === 'text' ? 'webParagraph02' : 'paragraph02'}>
            <FormattedMessage id={`language.${locale}` as I18nKey} />
          </Typography>
        </Box>
        <IcoArrow
          style={{
            fill: theme.palette.global01[400],
            width: '24px',
            height: '24px',
            marginLeft: '7.5px',
          }}
        />
      </DropDownButton>
      <DropDownMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {router.locales?.map((option, index) => (
          <DropDownItem
            $selected={!!(option === locale)}
            disableRipple
            disableTouchRipple
            key={index}
            onClick={() => handleLocaleClick(option as Locale)}
          >
            <Typography variant="paragraph02">
              <FormattedMessage id={`language.${option}` as I18nKey} />
            </Typography>
          </DropDownItem>
        ))}
      </DropDownMenu>
    </>
  );
};

export default LanguageMenu;
