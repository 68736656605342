import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoFlag = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.666992 11.3334V0.666748"
      stroke="#666875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M0.666992 8.70614L2.16222 8.24629C2.78161 8.0556 3.43455 7.99925 4.07744 8.081C4.72034 8.16275 5.33839 8.38072 5.89034 8.72036C6.42897 9.05234 7.03081 9.26856 7.65756 9.35523C8.28431 9.44191 8.92224 9.39716 9.53075 9.22383L10.9468 8.81897C11.0583 8.78713 11.1563 8.71983 11.2261 8.62728C11.2959 8.53472 11.3337 8.42194 11.3337 8.30602V2.74655C11.3336 2.66403 11.3144 2.58264 11.2775 2.50879C11.2407 2.43495 11.1872 2.37064 11.1214 2.32094C11.0555 2.27123 10.979 2.23747 10.8979 2.22232C10.8167 2.20717 10.7332 2.21103 10.6538 2.2336L9.53075 2.55455C8.92244 2.72831 8.28461 2.77353 7.65786 2.68734C7.03112 2.60115 6.42917 2.38544 5.89034 2.05393C5.33839 1.71429 4.72034 1.49632 4.07744 1.41457C3.43455 1.33282 2.78161 1.38917 2.16222 1.57986L0.666992 2.03923"
      stroke="#666875"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(SvgIcoFlag);
export default Memo;
