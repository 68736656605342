/* eslint-disable @typescript-eslint/no-explicit-any */
import config from 'config';

import axiosClient from './axiosService';
import {
  ContactFormStrapiType,
  CVFormStrapiType,
  EmailRegisteredStrapiType,
  EventStrapi,
  MainLayoutStrapi,
  PageData,
  ServiceStrapi,
} from './types';

export interface ListResponse<T> {
  data: T[] | [];
  meta: {
    pagination: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export const pageService = {
  getPageData: async (params: string): Promise<ListResponse<PageData>> => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/pages?populate=deep&${params}`,
    });
  },
};

export const defaultLayoutService = {
  getLayoutData: async (params: string): Promise<MainLayoutStrapi> => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/main-layout?populate=deep&locale=${params}`,
    });
  },
};

export const layoutService = {
  getHomePageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/home-page?populate=deep&${params}`,
    });
  },
  getOverBelgaPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/over-belga?populate=deep&${params}`,
    });
  },
  getJobsPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/job-page?populate=deep&${params}`,
    });
  },
  getPrivacyPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/privacy?populate=deep&${params}`,
    });
  },
  getTermPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/term?populate=deep&${params}`,
    });
  },
  getContactPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/contact?populate=deep&${params}`,
    });
  },
  getCustomerSupportPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/customer-support?populate=deep&${params}`,
    });
  },
  getAllEventPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/all-event?populate=deep&${params}`,
    });
  },
  getDiversityPolicyPageData: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/diversity-policy?populate=deep&${params}`,
    });
  },
};

export const ServicesService = {
  getAllServices: async (params: string): Promise<ListResponse<ServiceStrapi>> => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/services?${params}`,
    });
  },
  getServiceBySlug: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/services?${params}`,
    });
  },
};

export const EventsService = {
  getAllEvents: async (params: string): Promise<ListResponse<EventStrapi>> => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/events?populate=deep&${params}`,
    });
  },
  getEventsBySlug: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/events?populate=deep&?${params}`,
    });
  },
};

export const DirectsService = {
  getAllDirects: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/directs?populate=deep&${params}`,
    });
  },
};

export const JobsService = {
  getAllJobs: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/jobs?populate=deep&${params}`,
    });
  },
  getJobsBySlug: async (params: string) => {
    return (await axiosClient())({
      method: 'GET',
      url: `${config.API_BASE}/api/jobs?populate=deep&?${params}`,
    });
  },
};

export const ContactFormService = {
  createNewEntry: async (data: ContactFormStrapiType) => {
    return (await axiosClient())({
      method: 'POST',
      url: `${config.API_BASE}/api/contact-forms`,
      data: { data: data },
    });
  },
};

export const CVFormService = {
  createNewEntry: async (dataSubmit: CVFormStrapiType) => {
    return (await axiosClient())({
      method: 'POST',
      url: `${config.API_BASE}/api/cv-forms`,
      data: {
        data: dataSubmit,
      },
    });
  },
};

export const UploadFileService = {
  createNewEntry: async (dataSubmit: FormData) => {
    return (await axiosClient())({
      headers: {
        accept: 'application/json',
        'content-type': 'multipart/form-data',
      },
      method: 'POST',
      url: `${config.API_BASE}/api/upload`,
      data: dataSubmit,
    });
  },
};

export const EmailRegisteredService = {
  createNewEntry: async (dataSubmit: EmailRegisteredStrapiType) => {
    return (await axiosClient())({
      method: 'POST',
      url: `${config.API_BASE}/api/email-registereds`,
      data: {
        data: dataSubmit,
      },
    });
  },
};
