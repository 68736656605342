/* eslint-disable react/display-name */
import { Dialog, useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import React, { useImperativeHandle, useState } from 'react';

import useMedia from 'hooks/useMedia';
import { theme } from 'theme';

import { ContactForm } from './ContactForm/ContactForm';
import { CVForm } from './CVForm/CVForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const globalFormRef = React.createRef<any>();

export const globalModal = {
  show: () => {
    globalFormRef?.current?.show();
  },
  hide: () => {
    globalFormRef?.current?.hide();
  },
};

const GlobalForm = React.forwardRef((__props, ref) => {
  const [visible, setVisible] = useState<boolean>(false);
  const router = useRouter();

  useImperativeHandle(ref, () => {
    return { show: show, hide: hide };
  });

  const show = () => {
    setVisible(true);
  };

  const hide = () => {
    setVisible(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { isMedium } = useMedia();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={visible}
      onClose={hide}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          borderRadius: !isMedium ? theme.spacing(3) : theme.spacing(0),
          minWidth: '30%',
        },
        sx: {
          overflow: 'hidden',
        },
      }}
    >
      {router.asPath.includes('jobs') ? <CVForm /> : <ContactForm />}
    </Dialog>
  );
});

export default GlobalForm;
