import { Box, InputLabel, TextField as MuiTextField, Typography } from '@mui/material';
import { HTMLInputTypeAttribute } from 'react';
import { FormattedMessage } from 'react-intl';

import { theme } from 'theme';

import { IcoWarningFilled } from 'components/@icons';

interface Props extends React.ComponentProps<typeof Box> {
  label?: string;
  placeholder?: string;
  multiline?: boolean;
  value: string;
  hasError?: boolean;
  type?: HTMLInputTypeAttribute;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  maxLength?: number;
  handleChange: (value: string) => void;
  autoFocus?: boolean;
  hint?: I18nKey;
  errorMessage?: I18nKey | null;
}

const TextField = ({
  value,
  handleChange,
  hasError,
  type,
  inputRef,
  label,
  multiline,
  maxLength = multiline ? 2000 : 100,
  autoFocus,
  hint,
  errorMessage,
  placeholder,
  ...props
}: Props) => {
  return (
    <Box {...props}>
      {label && (
        <InputLabel htmlFor={label}>
          {' '}
          <Typography color={theme.palette.global01} fontSize={10} sx={{ marginTop: 1 }}>
            {label}
          </Typography>
        </InputLabel>
      )}

      <MuiTextField
        inputProps={{ maxLength }}
        error={hasError}
        inputRef={inputRef}
        type={type}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
        id={label}
        multiline={multiline}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {hint && (
        <Typography color="neutral01.300" fontSize={10} sx={{ marginTop: 1 }}>
          <FormattedMessage id={hint} />
        </Typography>
      )}
      {errorMessage && hasError && (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 1 }}>
          <IcoWarningFilled height={19} width={19} fill={theme.palette.error.dark} />
          <Typography color="error.dark" fontSize={14} fontWeight={400}>
            <FormattedMessage id={errorMessage} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TextField;
