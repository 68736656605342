export const fileTypeMapping: { [key: string]: string } = {
  'application/zip': 'file/.zip',
  'application/x-rar-compressed': 'file/.rar',
  'image/png': 'image/.png',
  'application/pdf': 'image/.pdf',
  'image/svg+xml': 'image/.svg',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file/.docx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file/.xlsx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'file/.pptx',
  'application/vnd.ms-powerpoint': 'file/.pptx',
  'audio/mp4': 'file/.mp4a',
  'video/mp4': 'file/.mp4',
};

export const getFileType = (fileType: string): string => {
  if (!(fileType in fileTypeMapping)) {
    return 'file';
  } else {
    return fileTypeMapping[fileType];
  }
};
