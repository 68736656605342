import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArticle = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="#000000" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3.5C4.61929 3.5 3.5 4.61929 3.5 6V18C3.5 19.3807 4.61929 20.5 6 20.5H18C19.3807 20.5 20.5 19.3807 20.5 18V6C20.5 4.61929 19.3807 3.5 18 3.5H6ZM4.5 6C4.5 5.17157 5.17157 4.5 6 4.5H18C18.8284 4.5 19.5 5.17157 19.5 6V18C19.5 18.8284 18.8284 19.5 18 19.5H6C5.17157 19.5 4.5 18.8284 4.5 18V6ZM12.167 8C12.167 7.72386 12.3909 7.5 12.667 7.5H17.0003C17.2765 7.5 17.5003 7.72386 17.5003 8C17.5003 8.27614 17.2765 8.5 17.0003 8.5H12.667C12.3909 8.5 12.167 8.27614 12.167 8ZM12.167 9.99998C12.167 9.72383 12.3909 9.49998 12.667 9.49998H17.0003C17.2765 9.49998 17.5003 9.72383 17.5003 9.99998C17.5003 10.2761 17.2765 10.5 17.0003 10.5H12.667C12.3909 10.5 12.167 10.2761 12.167 9.99998ZM12.667 11.5C12.3909 11.5 12.167 11.7239 12.167 12C12.167 12.2761 12.3909 12.5 12.667 12.5H17.0003C17.2765 12.5 17.5003 12.2761 17.5003 12C17.5003 11.7239 17.2765 11.5 17.0003 11.5H12.667ZM6.5 14C6.5 13.7238 6.72386 13.5 7 13.5H17C17.2761 13.5 17.5 13.7238 17.5 14C17.5 14.2761 17.2761 14.5 17 14.5H7C6.72386 14.5 6.5 14.2761 6.5 14ZM7 15.5C6.72386 15.5 6.5 15.7239 6.5 16C6.5 16.2762 6.72386 16.5 7 16.5H17C17.2761 16.5 17.5 16.2762 17.5 16C17.5 15.7239 17.2761 15.5 17 15.5H7ZM6.5 8C6.5 7.72386 6.72386 7.5 7 7.5H11C11.2761 7.5 11.5 7.72386 11.5 8V12C11.5 12.2761 11.2761 12.5 11 12.5H7C6.72386 12.5 6.5 12.2761 6.5 12V8ZM7.5 8.5V11.5H10.5V8.5H7.5Z"
    />
  </svg>
);
const Memo = memo(SvgIcoArticle);
export default Memo;
