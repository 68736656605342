import { makeStyles } from '@material-ui/core';
import { Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import QueryString from 'qs';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getServicesByCriteria } from 'redux/slices/servicesSlice';
import { AppDispatch, RootState } from 'redux/store';

import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import { ItemLayoutStrapi, ServiceStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoArrow } from 'components/@icons';

import {
  MenuMobileButton,
  SubMenuItem,
  SubMenuMobile,
  SubMenuMobileContent,
} from './NavigationMobileItem.style';

const useStyles = makeStyles(() => ({
  activated: {
    color: theme.palette.global01[400],
    fontWeight: 700,
  },
}));

interface MenuProps {
  menuData?: { group: string; items: ItemLayoutStrapi[] };
  isServicesMenu?: boolean;
}

const NavigationGroupItemMobile = ({ menuData, isServicesMenu }: MenuProps) => {
  const router = useRouter();
  const classes = useStyles();
  const { locale } = useLocale();
  const dispatch = useDispatch<AppDispatch>();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const services = useSelector<RootState, ServiceStrapi[]>((state) => state.serviceStore.services);
  const toggleSubMenu = (open: boolean | undefined = undefined) => {
    setIsSubMenuOpen((prev) => open ?? !prev);
  };

  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = isSubMenuOpen ? 'hidden' : overflow;
    return () => {
      document.body.style.overflow = overflow;
    };
  }, [isSubMenuOpen]);

  useEffect(() => {
    toggleSubMenu(false);
  }, [router.asPath, router.locale]);

  useEffect(() => {
    if (locale) {
      loadData(languageCodeMap[locale]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const loadData = async (locale: string) => {
    let getServicesQuery = {
      locale: locale,
      populate: 'deep',
    };
    dispatch(
      getServicesByCriteria(
        QueryString.stringify(getServicesQuery, {
          encodeValuesOnly: true,
        }),
      ),
    );
  };

  return (
    <>
      <MenuMobileButton
        endIcon={
          <IcoArrow
            style={{
              transform: isSubMenuOpen ? 'rotate(180deg)' : 'rotate(-360deg)',
              fill: theme.palette.global01[400],
              width: '24px',
              height: '24px',
            }}
          />
        }
        variant="text"
        onTouchStart={() => toggleSubMenu()}
      >
        <Typography
          variant="heading05"
          sx={(theme) => ({
            color: isSubMenuOpen ? theme.palette.global01[400] : theme.palette.text.primary,
          })}
          className={router.pathname.includes('services') ? classes.activated : ''}
        >
          {isServicesMenu ? <FormattedMessage id="common.service" /> : menuData?.group}
        </Typography>
      </MenuMobileButton>
      {isSubMenuOpen && isServicesMenu && services && (
        <SubMenuMobile $isOpen={isSubMenuOpen}>
          <SubMenuMobileContent>
            {services &&
              services.map((service, index) => (
                <SubMenuItem
                  onClick={() => {
                    toggleSubMenu();
                  }}
                  href={`/services/${service.attributes.url}`}
                  key={index}
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography
                      fontWeight={700}
                      variant="heading05"
                      className={
                        router.asPath.includes(`${service.attributes.url}`) ? classes.activated : ''
                      }
                    >
                      {service.attributes.title}
                    </Typography>
                  </Stack>
                </SubMenuItem>
              ))}
          </SubMenuMobileContent>
        </SubMenuMobile>
      )}
      {isSubMenuOpen && !isServicesMenu && menuData && (
        <SubMenuMobile $isOpen={isSubMenuOpen}>
          <SubMenuMobileContent>
            {menuData &&
              menuData.items.map((menuDataItem, index) => (
                <SubMenuItem
                  onClick={() => {
                    toggleSubMenu();
                  }}
                  href={`/${menuData?.group}/${menuDataItem.url}`}
                  key={index}
                >
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography fontWeight={700} variant="heading05">
                      {menuDataItem.label}
                    </Typography>
                  </Stack>
                </SubMenuItem>
              ))}
          </SubMenuMobileContent>
        </SubMenuMobile>
      )}
    </>
  );
};

export default NavigationGroupItemMobile;
