import { Components, paginationItemClasses, Theme } from '@mui/material';

export const MuiPagination: Components<Theme>['MuiPagination'] = {
  styleOverrides: {
    root: {
      padding: '6px 14px',
      border: '#3349E8',
    },
  },
};

export const MuiPaginationItem: Components<Theme>['MuiPaginationItem'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.neutral01[300],
      borderColor: theme.palette.neutral01[600],
      fontSize: '0.8rem',
      [`&.${paginationItemClasses.selected}`]: {
        backgroundColor: theme.palette.global01[400],
        color: theme.palette.neutral01[700],
      },
      [`&.${paginationItemClasses.selected}:hover`]: {
        backgroundColor: theme.palette.global01[400],
        color: theme.palette.neutral01[700],
      },
      [`&.${paginationItemClasses.previousNext}`]: {
        color: theme.palette.neutral01[300],
      },
      '&:hover': {
        backgroundColor: theme.palette.global01[700],
      },
    }),
  },
};
