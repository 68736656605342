import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnderline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86621 4.5C6.59007 4.5 6.36621 4.72386 6.36621 5C6.36621 5.27614 6.59007 5.5 6.86621 5.5H7.29883V12.0012C7.29883 13.2478 7.79401 14.4432 8.67543 15.3246C9.55685 16.2061 10.7523 16.7012 11.9988 16.7012C13.2453 16.7012 14.4408 16.2061 15.3222 15.3246C16.2036 14.4432 16.6988 13.2478 16.6988 12.0012V5.5H17.1333C17.4094 5.5 17.6333 5.27614 17.6333 5C17.6333 4.72386 17.4094 4.5 17.1333 4.5H16.1988H15.2666C14.9905 4.5 14.7666 4.72386 14.7666 5C14.7666 5.27614 14.9905 5.5 15.2666 5.5H15.6988V12.0012C15.6988 12.9825 15.309 13.9237 14.6151 14.6175C13.9212 15.3114 12.9801 15.7012 11.9988 15.7012C11.0175 15.7012 10.0764 15.3114 9.38253 14.6175C8.68865 13.9237 8.29883 12.9825 8.29883 12.0012V5.5H8.73288C9.00902 5.5 9.23288 5.27614 9.23288 5C9.23288 4.72386 9.00902 4.5 8.73288 4.5H7.79883H6.86621ZM5 18.5C4.72386 18.5 4.5 18.7239 4.5 19C4.5 19.2761 4.72386 19.5 5 19.5H19C19.2761 19.5 19.5 19.2761 19.5 19C19.5 18.7239 19.2761 18.5 19 18.5H5Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoUnderline);
export default Memo;
