/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputLabel,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { languageCodeMap } from 'utils/localConvert';
import { regexEmail, regexNoWhitespace, regexPhoneNumber } from 'utils/regexUtils';

import { useLocale } from 'hooks/useLocale';
import useMedia from 'hooks/useMedia';
import { CVFormService, UploadFileService } from 'services/api';
import { CVFormStrapiType } from 'services/api/types';
import { theme } from 'theme';

import { TextField } from 'components/@common';
import { FileUpload } from 'components/@common/FileUpload/FileUpload';
import { IcoClose } from 'components/@icons';

import { globalModal } from '../GlobalForm';

export const CVForm = () => {
  const intl = useIntl();
  const { locale } = useLocale();
  const { isMedium } = useMedia();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CVFormStrapiType>();

  const [isRecaptchaSuccess, setIsRecaptchaSuccess] = useState<boolean>(false);

  const onChangeRecaptcha = () => {
    setIsRecaptchaSuccess(true);
  };

  const onSubmitForm = handleSubmit((data) => {
    if (isRecaptchaSuccess) {
      const dataForSubmit = { ...data, locale: languageCodeMap[locale] };
      let formData = new FormData();
      data.files.forEach((file) => {
        formData.append(`files`, file);
      });
      toast.loading(intl.formatMessage({ id: 'common.notification.loading' }));
      handleUploadFile(formData, dataForSubmit);
    }
  });

  const handleUploadFile = async (file: FormData, dataForSubmit: CVFormStrapiType) => {
    try {
      await UploadFileService.createNewEntry(file).then((response) => {
        let filesUploadId: string[] = [];
        if (Array.isArray(response)) {
          response.map((file) => {
            filesUploadId.push(file.id);
          });
        }
        const data = { ...dataForSubmit, files: filesUploadId };
        handleSubmitForm(data);
      });
    } catch (error) {
      Sentry.captureException(error);
      toast.error(intl.formatMessage({ id: 'common.notification.error' }));
    }
  };

  const handleSubmitForm = async (data: any) => {
    try {
      const response = await CVFormService.createNewEntry(data);
      if (response && response?.data) {
        toast.dismiss();
        globalModal.hide();
        toast.success(intl.formatMessage({ id: 'common.notification.success' }));
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'common.notification.error' }));
    }
  };

  return (
    <Container
      sx={{ paddingBlock: theme.spacing(4), marginBlock: theme.spacing(2), overflow: 'auto' }}
    >
      {isMedium ? (
        <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
            width: '100%',
            marginBottom: theme.spacing(2),
          }}
        >
          <DialogActions
            sx={(theme) => ({
              height: { xs: 56, md: 64 },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              zIndex: 1001,
              backgroundColor: theme.palette.neutral01[700],
              padding: 0,
            })}
          >
            <DialogTitle sx={{ padding: 0 }}>
              <Typography variant="heading03" fontWeight={800}>
                <FormattedMessage id="cvForm.title" />
              </Typography>
            </DialogTitle>
            <IcoClose width={'26px'} height={'26px'} onClick={() => globalModal.hide()} />
          </DialogActions>
        </Box>
      ) : (
        <DialogActions sx={{ justifyContent: 'space-between', padding: 0 }}>
          <DialogTitle sx={{ padding: 0 }}>
            <Typography variant="heading03" fontWeight={800}>
              <FormattedMessage id="cvForm.title" />
            </Typography>
          </DialogTitle>
          <Box
            sx={{
              backgroundColor: theme.palette.global01[700],
              padding: theme.spacing(1),
              borderRadius: theme.spacing(1),
              ':hover': {
                backgroundColor: theme.palette.global01[600],
                cursor: 'pointer',
              },
            }}
            onClick={() => globalModal.hide()}
          >
            <IcoClose
              width={'26px'}
              height={'26px'}
              style={{
                fill: theme.palette.primary.main,
              }}
            />
          </Box>
        </DialogActions>
      )}
      <DialogContent
        sx={{
          padding: 0,
          marginBottom: theme.spacing(2),
        }}
      >
        <DialogContentText>
          <Typography variant="paragraph01" fontWeight={400}>
            <FormattedMessage id="cvForm.desc" />
          </Typography>
        </DialogContentText>
        <DialogContent
          sx={{ padding: theme.spacing(2, 0), gap: theme.spacing(2), display: 'grid' }}
        >
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'cvForm.field_1' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                hasError={errors.firstName ? true : false}
                errorMessage={
                  errors?.firstName?.type == 'required'
                    ? 'cvForm.error.required'
                    : 'cvForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'cvForm.field_1' })}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'cvForm.field_2' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                hasError={errors.lastName ? true : false}
                errorMessage={
                  errors?.lastName?.type == 'required'
                    ? 'cvForm.error.required'
                    : 'cvForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'cvForm.field_2' })}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexEmail,
                message: intl.formatMessage({ id: 'cvForm.field_3' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                type="email"
                hasError={errors.email ? true : false}
                errorMessage={
                  errors?.email?.type == 'required'
                    ? 'cvForm.error.required'
                    : 'cvForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'cvForm.field_3' })}
              />
            )}
          />
          <Controller
            name="phoneNumber"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexPhoneNumber,
                message: intl.formatMessage({ id: 'cvForm.field_4' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                hasError={errors.phoneNumber ? true : false}
                errorMessage={
                  errors?.phoneNumber?.type == 'required'
                    ? 'cvForm.error.required'
                    : 'cvForm.error.pattern'
                }
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'cvForm.field_4' })}
              />
            )}
          />
          <Controller
            name="describe"
            control={control}
            defaultValue=""
            rules={{
              required: true,
              pattern: {
                value: regexNoWhitespace,
                message: intl.formatMessage({ id: 'cvForm.field_5' }),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                value={field.value || ''}
                hasError={errors.describe ? true : false}
                errorMessage={
                  errors?.describe?.type == 'required'
                    ? 'cvForm.error.required'
                    : 'cvForm.error.pattern'
                }
                multiline
                handleChange={(value) => field.onChange(value)}
                label={intl.formatMessage({ id: 'cvForm.field_5' })}
              />
            )}
          />

          <Box>
            <InputLabel>
              <Typography color={theme.palette.global01} fontSize={10} sx={{ marginTop: 1 }}>
                <FormattedMessage id="cvForm.field_6" />
              </Typography>
            </InputLabel>
            <Controller
              name="files"
              control={control}
              defaultValue={[]}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <FileUpload
                  onFilesChange={(files) => field.onChange(files)}
                  hasError={Boolean(errors.files)}
                  errorMessage={
                    errors?.files?.type == 'required'
                      ? 'cvForm.error.required.file'
                      : 'cvForm.error.pattern.file'
                  }
                />
              )}
            />
          </Box>
          <ReCAPTCHA
            sitekey={'6Ld7da8oAAAAAHQg8lXtyqMKgg1H4vBhOTOP603c'}
            onChange={onChangeRecaptcha}
          />
        </DialogContent>
        <DialogActions
          sx={{ justifyContent: 'space-between', padding: 0, marginBottom: theme.spacing(2) }}
        >
          <Button
            variant="contained"
            size="large"
            fullWidth={isMedium ? true : false}
            onClick={onSubmitForm}
          >
            <FormattedMessage id="cvForm.labelButtonSubmit" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Container>
  );
};
