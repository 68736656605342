import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnorderedList = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 6.4C5.5 5.90294 5.90294 5.5 6.4 5.5C6.89706 5.5 7.3 5.90294 7.3 6.4C7.3 6.89706 6.89706 7.3 6.4 7.3C5.90294 7.3 5.5 6.89706 5.5 6.4ZM6.4 4.5C5.35066 4.5 4.5 5.35066 4.5 6.4C4.5 7.44934 5.35066 8.3 6.4 8.3C7.44934 8.3 8.3 7.44934 8.3 6.4C8.3 5.35066 7.44934 4.5 6.4 4.5ZM5.5 12C5.5 11.5029 5.90294 11.1 6.4 11.1C6.89706 11.1 7.3 11.5029 7.3 12C7.3 12.4971 6.89706 12.9 6.4 12.9C5.90294 12.9 5.5 12.4971 5.5 12ZM6.4 10.1C5.35066 10.1 4.5 10.9507 4.5 12C4.5 13.0493 5.35066 13.9 6.4 13.9C7.44934 13.9 8.3 13.0493 8.3 12C8.3 10.9507 7.44934 10.1 6.4 10.1ZM6.4 16.7C5.90294 16.7 5.5 17.1029 5.5 17.6C5.5 18.0971 5.90294 18.5 6.4 18.5C6.89706 18.5 7.3 18.0971 7.3 17.6C7.3 17.1029 6.89706 16.7 6.4 16.7ZM4.5 17.6C4.5 16.5507 5.35066 15.7 6.4 15.7C7.44934 15.7 8.3 16.5507 8.3 17.6C8.3 18.6493 7.44934 19.5 6.4 19.5C5.35066 19.5 4.5 18.6493 4.5 17.6ZM9.66758 5.90001C9.39144 5.90001 9.16758 6.12386 9.16758 6.40001C9.16758 6.67615 9.39144 6.90001 9.66758 6.90001H19.0009C19.2771 6.90001 19.5009 6.67615 19.5009 6.40001C19.5009 6.12386 19.2771 5.90001 19.0009 5.90001H9.66758ZM9.16758 12C9.16758 11.7239 9.39144 11.5 9.66758 11.5H19.0009C19.2771 11.5 19.5009 11.7239 19.5009 12C19.5009 12.2761 19.2771 12.5 19.0009 12.5H9.66758C9.39144 12.5 9.16758 12.2761 9.16758 12ZM9.66758 17.1C9.39144 17.1 9.16758 17.3239 9.16758 17.6C9.16758 17.8761 9.39144 18.1 9.66758 18.1H19.0009C19.2771 18.1 19.5009 17.8761 19.5009 17.6C19.5009 17.3239 19.2771 17.1 19.0009 17.1H9.66758Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoUnorderedList);
export default Memo;
