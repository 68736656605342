import { makeStyles } from '@material-ui/core';
import { Box, Button, Divider, Link, Stack, Typography } from '@mui/material';
import { Fade as Hamburger } from 'hamburger-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import useScreenHeight from 'hooks/useScreenHeight';
import useScrollDirection from 'hooks/useScrollDirection';
import { HeaderItemStrapi, ItemLayoutStrapi } from 'services/api/types';
import { theme } from 'theme';

import { globalModal } from 'components/@common/GlobalForm/GlobalForm';
import NavigationGroupItemMobile from 'components/@common/NavigationGroupItemMobile/NavigationMobileItem';
import LanguageMenu from 'components/@common/SelectDropdown';
import DirectMenu from 'components/@common/SelectDropdown/DirectMenu';
import { LogoBelgaColored } from 'components/@icons';

import {
  MenuIcon,
  MenuMobile,
  MenuMobileContent,
  MenuMobileLink,
  MobileAppBar,
} from './NavigationMobile.style';
import { useLocale } from 'hooks/useLocale';

const useStyles = makeStyles(() => ({
  activated: {
    color: theme.palette.global01[400],
    fontWeight: 700,
  },
}));

interface HeaderProps {
  headerData?: HeaderItemStrapi[];
}

const NavigationMobile = ({ headerData }: HeaderProps) => {
  const router = useRouter();
  const classes = useStyles();
  const { locale } = useLocale();
  const scrollDirection = useScrollDirection();
  const screenHeight = useScreenHeight();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = (open: boolean | undefined = undefined) => {
    setIsMenuOpen((prev) => open ?? !prev);
  };

  useEffect(() => {
    const overflow = document.body.style.overflow;
    document.body.style.overflow = isMenuOpen ? 'hidden' : overflow;
    return () => {
      document.body.style.overflow = overflow;
    };
  }, [isMenuOpen]);

  useEffect(() => {
    toggleMenu(false);
  }, [router.asPath, router.locale]);

  const groupHeaderItemByGroup = (header: HeaderItemStrapi[]) => {
    const groupedItems: { [group: string]: ItemLayoutStrapi[] } = {};

    for (const item of header) {
      const group = item.group;
      if (groupedItems[group]) {
        groupedItems[group].push(item);
      } else {
        groupedItems[group] = [item];
      }
    }

    const result: { group: string; items: ItemLayoutStrapi[] }[] = Object.entries(groupedItems).map(
      ([group, items]) => ({
        group,
        items,
      }),
    );
    return result;
  };

  return (
    <MobileAppBar
      $scrollDirection={scrollDirection}
      elevation={0}
      position="sticky"
      sx={(theme) => ({
        backgroundColor: theme.palette.neutral01[700],
        borderBottom: `1px solid ${theme.palette.neutral01[600]}`,
      })}
    >
      <Box
        sx={(theme) => ({
          height: { xs: 56, md: 64 },
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          zIndex: 1001,
          backgroundColor: theme.palette.neutral01[700],
          padding: theme.spacing(1, 2),
        })}
      >
        <Link href="/">
          <LogoBelgaColored style={{ width: '132px', height: '32px' }} />
        </Link>

        <MenuIcon aria-expanded={isMenuOpen} aria-label="Open the menu">
          <Hamburger duration={0.2} size={16} toggled={isMenuOpen} toggle={() => toggleMenu()} />
        </MenuIcon>
      </Box>
      <MenuMobile $height={screenHeight} $isOpen={isMenuOpen}>
        <MenuMobileContent>
          <Stack marginY={2} gap={2} direction="column">
            <MenuMobileLink href="/">
              <Typography
                variant="heading05"
                className={router.pathname === '/' ? classes.activated : ''}
              >
                <FormattedMessage id="common.home" />
              </Typography>
            </MenuMobileLink>
            <NavigationGroupItemMobile isServicesMenu />
            {headerData &&
              groupHeaderItemByGroup(headerData).map(
                (headerItem: { group: string; items: ItemLayoutStrapi[] }, index: number) => {
                  if (headerItem.group == 'null') {
                    return headerItem.items.map(
                      (headerItemInGroup: ItemLayoutStrapi, itemIndex: number) => {
                        return (
                          <MenuMobileLink
                            key={itemIndex}
                            href={headerItemInGroup.url || ''}
                            onClick={() => toggleMenu()}
                          >
                            <Typography
                              variant="heading05"
                              className={
                                router.asPath.includes(`${headerItemInGroup.url}`)
                                  ? classes.activated
                                  : ''
                              }
                            >
                              {headerItemInGroup.label}
                            </Typography>
                          </MenuMobileLink>
                        );
                      },
                    );
                  } else {
                    return <NavigationGroupItemMobile key={index} menuData={headerItem} />;
                  }
                },
              )}
          </Stack>

          <Stack justifyContent="flex-end" mt={4} gap={2} flexGrow={1} direction="column">
            <Stack direction="column" gap={1.5}>
              <Button fullWidth size="large" variant="contained" onClick={() => globalModal.show()}>
                {locale && locale === 'fr' && router.asPath.includes('jobs') ? (
                  'Postuler'
                ) : (
                  <FormattedMessage id="common.get_in_touch" />
                )}
              </Button>
            </Stack>
            <Divider />
            <DirectMenu fullWidth={true} variant="text" />
            <LanguageMenu fullWidth={true} variant="text" />
          </Stack>
        </MenuMobileContent>
      </MenuMobile>
    </MobileAppBar>
  );
};

export default NavigationMobile;
