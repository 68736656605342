import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoTags = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <title>{'ico_tags'}</title>
    <path d="M14.8,10a1,1,0,0,0,1,1.74,1,1,0,1,0-1-1.74Zm-.5-.86a2,2,0,1,1-.73,2.73A2,2,0,0,1,14.3,9.18Zm4.19,4.05A1.16,1.16,0,0,0,19,12.5l.85-4.15a.11.11,0,0,0-.07-.12l-4-1.33a1.09,1.09,0,0,0-.9.09L4.41,13a1.09,1.09,0,0,0-.4,1.5l2.5,4.33a1.1,1.1,0,0,0,1.5.41Zm-10,6.83a2,2,0,0,1-2.73-.74L3.23,15A2,2,0,0,1,4,12.26l10.48-6a2,2,0,0,1,1.63-.16l4,1.33a1,1,0,0,1,.66,1.15l-.85,4.15a2,2,0,0,1-1,1.33Zm-5-7.39A2,2,0,0,1,4.23,10L14.71,3.94a2,2,0,0,1,1.63-.16l4,1.33A1,1,0,0,1,21,6.26l-.34,1.65a1,1,0,0,0-.59-.53l-.2-.07.25-1.23A.09.09,0,0,0,20.07,6l-4-1.34a1.11,1.11,0,0,0-.89.09L4.68,10.77a1.11,1.11,0,0,0-.48,1.35L4,12.26A2,2,0,0,0,3.46,12.67Z" />
  </svg>
);
const Memo = memo(SvgIcoTags);
export default Memo;
