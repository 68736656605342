import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoLikes = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <title>{'ico_likes'}</title>
    <path d="M15.15,7a3,3,0,0,0-1.85.46,3.29,3.29,0,0,0-.57.48L12,8.73,11.27,8a3.2,3.2,0,0,0-.61-.49,3.1,3.1,0,0,0-2-.43,3.14,3.14,0,0,0-1.8,5.3l4.58,4.46a.77.77,0,0,0,1.08,0l4.54-4.42A3.19,3.19,0,0,0,18,10a3.12,3.12,0,0,0-1.07-2.25A3.07,3.07,0,0,0,15.15,7ZM12,7.27l.38-.36.37-.28A4.13,4.13,0,0,1,15.22,6a4,4,0,0,1,2.29.93l.07,0A4.11,4.11,0,0,1,19,10a4.19,4.19,0,0,1-1.19,3.09l-4.57,4.44a1.77,1.77,0,0,1-2.49,0l-1-1L6.19,13.05A4.21,4.21,0,0,1,5,10.15,4.07,4.07,0,0,1,8.56,6a4,4,0,0,1,2.62.57,2.69,2.69,0,0,1,.44.31Z" />
  </svg>
);
const Memo = memo(SvgIcoLikes);
export default Memo;
