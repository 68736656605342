import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPublish = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" role="img" {...props}>
    <path
      d="M20.1 4.10226C19.6 3.30226 18.5 3.00226 17.6 3.50226L3.8 12.2023C3.3 12.5023 3 13.1023 3 13.7023C3 14.7023 3.8 15.5023 4.8 15.5023H8.6V18.8023C8.6 19.8023 9.4 20.6023 10.4 20.6023C10.8 20.6023 11.1 20.5023 11.4 20.3023L13.3 19.0023L14.4 20.5023C14.7 20.9023 15.1 21.1023 15.5 21.2023C15.6 21.2023 15.7 21.2023 15.8 21.2023C16.6 21.2023 17.4 20.6023 17.6 19.7023L20.3 5.40226C20.4 5.00226 20.3 4.50226 20.1 4.10226ZM4.8 14.4023C4.4 14.4023 4.1 14.1023 4.1 13.7023C4.1 13.5023 4.2 13.2023 4.4 13.1023L18.1 4.50226L10.6 14.4023H9.1H4.8ZM10.8 19.3023C10.4 19.6023 9.7 19.3023 9.7 18.7023V15.4023H10.7L12.6 18.0023L10.8 19.3023ZM19.2 5.20226L16.5 19.5023C16.4 19.9023 16.1 20.1023 15.7 20.0023C15.5 20.0023 15.4 19.9023 15.3 19.7023L11.7 14.9023L19.2 5.00226C19.2 5.10226 19.2 5.20226 19.2 5.20226Z"
      fill="white"
    />
  </svg>
);
const Memo = memo(SvgIcoPublish);
export default Memo;
