export const regexPhoneNumber = new RegExp(
  '^(?!\\s+$)(?:\\+?[0-9]{1,3}-?[0-9]{1,10}|[0-9]{1,10})$',
);

export const regexEmail = new RegExp('^[\\w-_\\.+]*[\\w-_\\.]\\@([\\w]+\\.)+[\\w]+[\\w]$');

export const regexFullName =
  /^[a-vxyỳọáầảấờễàạằệếýộậốũứĩõúữịỗìềểẩớặòùồợãụủíỹắẫựỉỏừỷởóéửỵẳẹèẽổẵẻỡơôưăêâđ A-VXYỲỌÁẦẢẤỜỄÀẠẰỆẾÝỘẬỐŨỨĨÕÚỮỊỖÌỀỂẨỚẶÒÙỒỢÃỤỦÍỸẮẪỰỈỎỪỶỞÓÉỬỴẲẸÈẼỔẴẺỠ]{1,}$/gm;

export const regexNoWhitespace = new RegExp(/^(?!\s+$).+/);
