import Script from 'next/script';

import config from 'config';

const GtmHeaderScript = () => {
  return (
    // <Script
    //   id="gtm-header-script"
    //   strategy="afterInteractive"
    //   dangerouslySetInnerHTML={{
    //     __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //     'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${config.GTM_AUTH}&gtm_preview=${config.GTM_ENV}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
    //     })(window,document,'script','dataLayer','${config.GTM_ID}');`,
    //   }}
    // />
    <Script
      id="gtm-header-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        
        })(window,document,'script','dataLayer','${config.GTM_ID}');`,
      }}
    />
  );
};

export default GtmHeaderScript;
