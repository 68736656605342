import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoKeyword = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="black" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.40809 9.4177C8.40809 5.8733 11.2814 3 14.8258 3C18.3702 3 21.2435 5.8733 21.2435 9.4177C21.2435 12.9621 18.3702 15.8354 14.8258 15.8354C14.0933 15.8354 13.3887 15.7125 12.7319 15.4859L6.78267 21.4351C6.70617 21.5116 6.60692 21.5612 6.49983 21.5765L3.57072 21.995C3.41492 22.0172 3.25774 21.9648 3.14645 21.8535C3.03517 21.7423 2.98277 21.5851 3.00503 21.4293L3.42347 18.5002C3.43877 18.3931 3.4884 18.2938 3.5649 18.2173L4.40178 17.3804C4.40819 17.374 4.41476 17.3678 4.4215 17.3618L5.63075 16.2772C5.66878 16.2431 5.71182 16.215 5.75836 16.194L6.82603 15.7106L7.19416 13.8699C7.22935 13.694 7.35611 13.5504 7.52633 13.4937L8.66969 13.1125L9.22643 12.5558C8.70527 11.6277 8.40809 10.5568 8.40809 9.4177ZM14.8258 4C11.8337 4 9.40809 6.42559 9.40809 9.4177C9.40809 10.5036 9.72705 11.5135 10.2763 12.3606C10.4046 12.5586 10.3771 12.8193 10.2103 12.9862L9.29333 13.9031C9.23845 13.958 9.17154 13.9993 9.0979 14.0239L8.11783 14.3506L7.75629 16.1583C7.72471 16.3162 7.61896 16.4493 7.47223 16.5157L6.24104 17.0731L5.09929 18.0972L4.38985 18.8066L4.08926 20.9107L6.19341 20.6101L12.2522 14.5513C12.3942 14.4094 12.6074 14.3661 12.7935 14.4415C13.4204 14.6953 14.1061 14.8354 14.8258 14.8354C17.8179 14.8354 20.2435 12.4098 20.2435 9.4177C20.2435 6.42559 17.8179 4 14.8258 4ZM14.142 7.82594C14.142 6.56932 15.1607 5.55063 16.4173 5.55063C17.6739 5.55063 18.6926 6.56932 18.6926 7.82594C18.6926 9.08256 17.6739 10.1012 16.4173 10.1012C15.1607 10.1012 14.142 9.08256 14.142 7.82594ZM16.4173 6.55063C15.7129 6.55063 15.142 7.1216 15.142 7.82594C15.142 8.53027 15.7129 9.10125 16.4173 9.10125C17.1216 9.10125 17.6926 8.53027 17.6926 7.82594C17.6926 7.1216 17.1216 6.55063 16.4173 6.55063Z"
    />
  </svg>
);
const Memo = memo(SvgIcoKeyword);
export default Memo;
