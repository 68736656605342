import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoStrike = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8302 4.5C10.9267 4.5 10.0379 4.86354 9.37332 5.44311C8.70757 6.02374 8.2334 6.853 8.2334 7.8C8.2334 9.02437 8.78709 9.93571 9.55809 10.6585C10.0893 11.1565 10.7393 11.5792 11.3992 11.9667H5C4.72386 11.9667 4.5 12.1905 4.5 12.4667C4.5 12.7428 4.72386 12.9667 5 12.9667H13.1474C13.732 13.3147 14.2619 13.6684 14.6914 14.071C15.3204 14.6607 15.7001 15.3244 15.7001 16.2C15.7001 16.81 15.4577 17.395 15.0264 17.8263C14.5951 18.2577 14.0101 18.5 13.4001 18.5H8.7334C8.45726 18.5 8.2334 18.7239 8.2334 19C8.2334 19.2761 8.45726 19.5 8.7334 19.5H13.4001C14.2753 19.5 15.1146 19.1523 15.7335 18.5335C16.3524 17.9146 16.7001 17.0752 16.7001 16.2C16.7001 14.9756 16.1464 14.0643 15.3754 13.3415C15.2369 13.2117 15.0903 13.087 14.9377 12.9667H19C19.2761 12.9667 19.5 12.7428 19.5 12.4667C19.5 12.1905 19.2761 11.9667 19 11.9667H13.4201C13.1931 11.8349 12.9658 11.707 12.7424 11.5814L12.7119 11.5642C11.7651 11.0317 10.8894 10.5359 10.242 9.92898C9.61304 9.3393 9.2334 8.67563 9.2334 7.8C9.2334 7.20015 9.53451 6.62942 10.0306 6.19676C10.5279 5.76304 11.1874 5.5 11.8302 5.5H16.2001C16.4762 5.5 16.7001 5.27614 16.7001 5C16.7001 4.72386 16.4762 4.5 16.2001 4.5H11.8302Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoStrike);
export default Memo;
