import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab, Tooltip, useScrollTrigger, Zoom } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

const ScrollToTopButton: React.FC = () => {
  const intl = useIntl();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 300);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={trigger}>
      <Tooltip title={intl.formatMessage({ id: 'common.scroll_to_top' })} placement="top">
        <Fab
          color="primary"
          aria-label="scroll-to-top"
          onClick={handleClick}
          style={{
            width: '38px',
            height: '28px',
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            zIndex: 99995,
            opacity: isVisible ? 1 : 0,
            visibility: isVisible ? 'visible' : 'hidden',
            transition: 'opacity 0.3s ease',
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </Tooltip>
    </Zoom>
  );
};

export default ScrollToTopButton;
