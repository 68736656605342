import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoKeywordFast = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8279 9.68959C20.3611 11.4877 20.2594 13.4144 19.5398 15.1464C19.4339 15.4014 19.5547 15.694 19.8098 15.8C20.0648 15.9059 20.3574 15.7851 20.4633 15.53C21.2709 13.5861 21.3851 11.4235 20.7866 9.40529C20.1881 7.38712 18.9135 5.63632 17.1767 4.44689C15.4399 3.25746 13.3468 2.70184 11.2487 2.87331C9.52189 3.01445 7.87829 3.64109 6.50289 4.67008L6.50289 2.0014C6.50289 1.72526 6.27903 1.5014 6.00289 1.5014C5.72674 1.5014 5.50289 1.72526 5.50289 2.0014L5.50289 5.70293C5.49997 5.73411 5.49996 5.76553 5.50289 5.79674L5.50289 6.0014C5.50289 6.27754 5.72674 6.5014 6.00289 6.5014L10.0029 6.5014C10.279 6.5014 10.5029 6.27754 10.5029 6.0014C10.5029 5.72526 10.279 5.5014 10.0029 5.5014L7.06129 5.5014C8.2949 4.56646 9.77463 3.99713 11.3302 3.86999C13.1994 3.71721 15.0643 4.21225 16.6117 5.27196C18.159 6.33167 19.2947 7.89152 19.8279 9.68959ZM18.5029 18.0014C18.5029 17.7253 18.279 17.5014 18.0029 17.5014L14.0029 17.5014C13.7267 17.5014 13.5029 17.7253 13.5029 18.0014C13.5029 18.2775 13.7267 18.5014 14.0029 18.5014L16.9463 18.5014C15.7126 19.4392 14.2318 20.0108 12.6746 20.1392C10.804 20.2935 8.93742 19.7989 7.38868 18.7387C5.83994 17.6784 4.70359 16.1171 4.17074 14.3174C3.63789 12.5177 3.74107 10.5895 4.46291 8.85691C4.56912 8.60201 4.44857 8.30927 4.19367 8.20307C3.93876 8.09687 3.64603 8.21741 3.53983 8.47232C2.72961 10.417 2.61381 12.5813 3.21188 14.6013C3.80996 16.6213 5.08544 18.3737 6.82377 19.5638C8.56211 20.7539 10.6573 21.309 12.7568 21.1358C14.4843 20.9933 16.128 20.365 17.5029 19.3342L17.5029 22.0014C17.5029 22.2775 17.7267 22.5014 18.0029 22.5014C18.279 22.5014 18.5029 22.2775 18.5029 22.0014L18.5029 18.3063C18.5068 18.2703 18.5068 18.2339 18.5029 18.1979L18.5029 18.0014Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99507 12.8966L10.4804 12.742C10.5169 12.7304 10.55 12.7108 10.5772 12.6849L11.0313 12.2511C11.1139 12.1722 11.1275 12.0488 11.064 11.9552C10.792 11.5544 10.634 11.0767 10.634 10.563C10.634 9.14748 11.8352 8 13.317 8C14.7988 8 16 9.14748 16 10.563C16 11.9784 14.7988 13.1259 13.317 13.1259C12.9606 13.1259 12.621 13.0597 12.3106 12.9396C12.2184 12.9039 12.1128 12.9244 12.0425 12.9915L9.04204 15.8578L8.00001 16L8.14887 15.0046L8.5002 14.669L9.06563 14.1845L9.67535 13.9208C9.74801 13.8894 9.80038 13.8265 9.81602 13.7517L9.99507 12.8966ZM9.01629 12.6916C9.09016 12.3388 9.34819 12.0531 9.69166 11.9437L9.89371 11.8794C9.72656 11.4727 9.63403 11.0284 9.63403 10.563C9.63403 8.55255 11.3266 7 13.317 7C15.3075 7 17 8.55255 17 10.563C17 12.5734 15.3075 14.1259 13.317 14.1259C13.0069 14.1259 12.7047 14.0887 12.4154 14.0183L9.73279 16.5809C9.58044 16.7264 9.38601 16.8201 9.17725 16.8486L8.13522 16.9908C7.82331 17.0334 7.50956 16.9266 7.28839 16.7026C7.06722 16.4785 6.96445 16.1634 7.01101 15.8521L7.15987 14.8567C7.1927 14.6372 7.29763 14.4348 7.45812 14.2815L7.80946 13.9459C7.8225 13.9334 7.83588 13.9213 7.84958 13.9096L8.41501 13.4251C8.49114 13.3599 8.57664 13.3065 8.66866 13.2667L8.91852 13.1586L9.01629 12.6916Z"
      fill="currentColor"
    />
    <circle cx={14} cy={10} r={1} fill="currentColor" />
  </svg>
);
const Memo = memo(SvgIcoKeywordFast);
export default Memo;
