import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';

import { ServicesService } from 'services/api';
import { ServiceStrapi } from 'services/api/types';

export const getServicesByCriteria = createAsyncThunk(
  'services/getServicesByCriteria',
  async (params: string) => {
    try {
      const response = await ServicesService.getAllServices(params);
      return response;
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.log(error);
    }
  },
);

export type serviceStateType = {
  services: ServiceStrapi[] | [];
  meta: {
    pagination: {
      total: number;
      page: number;
      pageSize: number;
      pageCount: number;
    };
  };
};

const initialState: serviceStateType = {
  services: [],
  meta: {
    pagination: {
      total: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
    },
  },
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState: initialState,
  reducers: {
    changePage: (state, action) => {
      state.meta.pagination.page = action.payload;
    },

    changePageSize: (state, action) => {
      state.meta.pagination.pageSize = action.payload.pageSize;
      state.meta.pagination.page = action.payload.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServicesByCriteria.fulfilled, (state, action) => {
      if (action.payload?.data) {
        state.services = action.payload?.data;
      }
      if (action.payload?.meta) {
        state.meta = action.payload?.meta;
      }
    });
  },
});
const { reducer, actions } = servicesSlice;
export const { changePage, changePageSize } = actions;
export default reducer;
