import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoRecommended = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 20.1429C7.50985 20.1429 3.8567 16.4897 3.8567 12C3.8567 7.51031 7.50985 3.85716 11.9996 3.85716C16.4893 3.85716 20.1424 7.51031 20.1424 12C20.1424 16.4897 16.4893 20.1429 11.9996 20.1429ZM12 3C7.03714 3 3 7.03714 3 12C3 16.9629 7.03714 21 12 21C16.9629 21 21 16.9629 21 12C21 7.03714 16.9629 3 12 3ZM11.0471 12C11.0471 11.4737 11.4739 11.0477 11.9994 11.0477C12.5248 11.0477 12.9517 11.4737 12.9517 12C12.9517 12.5263 12.5248 12.9523 11.9994 12.9523C11.4739 12.9523 11.0471 12.5263 11.0471 12ZM13.7119 13.7279L7.82843 16.1365L10.2524 10.2865L16.1684 7.86423L13.7119 13.7279ZM16.0398 6.99083L9.92756 9.49369C9.73813 9.57169 9.58985 9.71826 9.51013 9.90855L6.95756 16.0003C6.83756 16.2883 6.90099 16.6174 7.12128 16.8394C7.26785 16.9877 7.46585 17.0674 7.66642 17.0674C7.76499 17.0674 7.86442 17.0485 7.9587 17.01L14.071 14.5071C14.2613 14.4291 14.4096 14.2817 14.4884 14.0914L17.041 7.99969C17.161 7.71169 17.0976 7.38255 16.8773 7.16055C16.6587 6.9394 16.3287 6.87255 16.0398 6.99083Z"
      fill="#666875"
    />
  </svg>
);
const Memo = memo(SvgIcoRecommended);
export default Memo;
