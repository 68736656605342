import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, IconButton, Typography } from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getFileType } from 'utils/file.utils';

import { theme } from 'theme';

import { IcoClose, IcoFile, IcoWarningFilled } from 'components/@icons';

const useStyles = makeStyles(() => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    minHeight: '80px',
    display: 'flex',
    borderRadius: '4px',
    flexDirection: 'column',
    zIndex: 10,
    cursor: 'pointer',
    transition: 'background-color 0.6s ease',
    backgroundColor: theme.palette.global01[700],
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.global01[600],
    },
    '&.dragging': {
      backgroundColor: theme.palette.global01[600],
      border: `1px dashed ${theme.palette.global01[500]}`,
    },
    '&.empty': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.global01[700],
      color: theme.palette.global01[300],
    },
    padding: '8px 8px 8px 16px',
    backgroundColor: theme.palette.neutral01[700],
    borderRadius: theme.spacing(1),
    zIndex: 999999,
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    color: theme.palette.global01[200],
    fontWeight: 600,
  },
  fileType: {
    color: theme.palette.neutral01[300],
    fontSize: '0.8rem',
  },
  fileSize: {
    color: theme.palette.neutral01[300],
    fontSize: '0.8rem',
  },
  invalidFile: {
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

interface FileUploadProps {
  onFilesChange: (files: File[]) => void;
  hasError?: boolean;
  errorMessage?: I18nKey | null;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  onFilesChange,
  errorMessage,
  hasError = false,
}) => {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);

  useEffect(() => {
    handleCheckFile(files);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const handlePaperClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCheckFile = (files: File[]) => {
    const filteredFiles = files.filter((file) => {
      const fileType = file.type.split('/')[1]; // Extract the file extension
      return (
        fileType === 'png' ||
        fileType === 'pdf' ||
        fileType === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
      ); // Accept only PNG and PDF files
    });
    const invalidFilesAfterChecked = files.filter((file) => !filteredFiles.includes(file));
    // Store invalid files separately
    setInvalidFiles([...invalidFilesAfterChecked]);
    if (invalidFilesAfterChecked.length == 0) {
      onFilesChange(files);
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileList = Array.from(event.target.files);
      setFiles([...files, ...fileList]);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const fileList = Array.from(event.dataTransfer.files) as File[];
    setFiles([...files, ...fileList]);
    setIsDragging(false);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleFileRemove = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <Container sx={{ width: '100%', padding: { xl: 0, lg: 0, md: 0, sx: 0, xs: 0 }, margin: 0 }}>
      <Grid container>
        <Grid item xs={12}>
          <Paper
            className={`${classes.paper} ${isDragging ? 'dragging' : ''}   ${
              files.length > 0 ? '' : 'empty'
            }`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onClick={handlePaperClick}
          >
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleFileInputChange}
              style={{ display: 'none' }}
              accept=".png, .pdf, .docx"
            />
            {files.length == 0 && (
              <Typography variant="paragraph02" sx={{ color: theme.palette.neutral01[300] }}>
                {isDragging ? (
                  <FormattedMessage id="cvForm.labelWhenHoverWithFile" />
                ) : (
                  <FormattedMessage id="cvForm.labelUploadFileWhenEmpty" />
                )}
              </Typography>
            )}
            <Grid item>
              <ul className={classes.list}>
                {files.map((file, index) => (
                  <li
                    key={file.name}
                    className={`${classes.listItem} ${
                      invalidFiles.includes(file) ? classes.invalidFile : ''
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Box sx={{ marginRight: theme.spacing(2), fill: theme.palette.global01[200] }}>
                      <IcoFile style={{ width: '32px', height: '32px' }} />
                    </Box>
                    <Box sx={{ display: 'grid', width: '100%' }}>
                      <Typography className={classes.fileName} variant="paragraph01">
                        {file.name}
                      </Typography>
                      <Box sx={{ display: 'flex', maxWidth: '100%' }}>
                        <Typography className={classes.fileType} variant="body1">
                          {getFileType(file.type)}
                          <Fragment>&nbsp;{' - '}&nbsp;</Fragment>
                        </Typography>
                        <Typography className={classes.fileSize} variant="body1">
                          {` ${(file.size / 1024).toFixed(2)} KB`}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => handleFileRemove(index)}
                      sx={{ cursor: 'pointer', fill: theme.palette.global01[400] }}
                    >
                      <IcoClose style={{ width: '24px', height: '24px' }} />
                    </IconButton>
                  </li>
                ))}
              </ul>
            </Grid>

            {files.length > 0 && (
              <Typography variant="paragraph02" sx={{ color: theme.palette.neutral01[300] }}>
                <FormattedMessage id="cvForm.labelUploadFileWhenFull" />
              </Typography>
            )}
          </Paper>
          {errorMessage && hasError && invalidFiles.length == 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 1 }}>
              <IcoWarningFilled height={19} width={19} fill={theme.palette.error.dark} />
              <Typography color="error.dark" fontSize={14} fontWeight={400}>
                <FormattedMessage id="cvForm.error.required.file" />
              </Typography>
            </Box>
          )}

          {invalidFiles.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 1 }}>
              <IcoWarningFilled height={19} width={19} fill={theme.palette.error.dark} />
              <Typography color="error.dark" fontSize={14} fontWeight={400}>
                <FormattedMessage id="cvForm.error.pattern.file" />
                <FormattedMessage id="cvForm.error.try_another.file" />
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
