import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoCollaboration = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <path d="M7.77,18.08A2.33,2.33,0,0,0,9,16,2.37,2.37,0,1,0,4.3,16a2.34,2.34,0,0,0,1.27,2.08,5.48,5.48,0,0,0-.56.23,3.61,3.61,0,0,0-1.45,1.45.5.5,0,1,0,.88.48,2.52,2.52,0,0,1,4.45,0,.51.51,0,0,0,.44.26.53.53,0,0,0,.24-.06.5.5,0,0,0,.2-.68A3.53,3.53,0,0,0,7.77,18.08Zm-1.1-3.45A1.37,1.37,0,1,1,5.3,16,1.37,1.37,0,0,1,6.67,14.63Z" />
    <path d="M20.44,19.76a3.52,3.52,0,0,0-2-1.68A2.34,2.34,0,0,0,19.7,16,2.37,2.37,0,1,0,15,16a2.33,2.33,0,0,0,1.26,2.08,5.31,5.31,0,0,0-.55.23,3.55,3.55,0,0,0-1.45,1.45.5.5,0,0,0,.88.48,2.38,2.38,0,0,1,1-1A2.47,2.47,0,0,1,18.07,19a2.53,2.53,0,0,1,1.49,1.23.51.51,0,0,0,.44.26.53.53,0,0,0,.24-.06A.5.5,0,0,0,20.44,19.76Zm-3.11-5.13A1.37,1.37,0,1,1,16,16,1.37,1.37,0,0,1,17.33,14.63Z" />
    <path d="M9.69,9.83a.52.52,0,0,0,.38-.17,2,2,0,0,1,.29-.29,2.53,2.53,0,0,1,3.57.29A.5.5,0,0,0,14.69,9,3.55,3.55,0,0,0,13.1,8a2.37,2.37,0,1,0-2.21,0,3.64,3.64,0,0,0-1.18.67,3,3,0,0,0-.4.4.48.48,0,0,0,.06.7A.47.47,0,0,0,9.69,9.83ZM12,4.5a1.37,1.37,0,1,1-1.37,1.37A1.37,1.37,0,0,1,12,4.5Z" />
    <path d="M14.6,17.29A.5.5,0,0,0,14,17a5.33,5.33,0,0,1-3.87,0,.5.5,0,0,0-.64.29.51.51,0,0,0,.28.65,6.43,6.43,0,0,0,2.28.42,6.36,6.36,0,0,0,2.31-.44A.5.5,0,0,0,14.6,17.29Z" />
    <path d="M6.16,13h0a.5.5,0,0,0,.46-.54,3.91,3.91,0,0,1,0-.49A5.39,5.39,0,0,1,8.32,8.1a.5.5,0,1,0-.68-.73,6.34,6.34,0,0,0-2,4.63,5.78,5.78,0,0,0,0,.58A.49.49,0,0,0,6.16,13Z" />
    <path d="M17.37,12a4,4,0,0,1,0,.49.5.5,0,0,0,.46.54h0a.5.5,0,0,0,.5-.45c0-.19,0-.39,0-.58a6.34,6.34,0,0,0-2-4.63.5.5,0,0,0-.71,0,.5.5,0,0,0,0,.71A5.37,5.37,0,0,1,17.37,12Z" />
  </svg>
);
const Memo = memo(SvgIcoCollaboration);
export default Memo;
