import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoAnalysisLine = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.5C4 5.22386 4.22386 5 4.5 5C4.77614 5 5 5.22386 5 5.5V18H19.5C19.7761 18 20 18.2239 20 18.5C20 18.7761 19.7761 19 19.5 19H4.5C4.22386 19 4 18.7761 4 18.5V5.5ZM18.7955 8.09677C19.0182 8.26007 19.0663 8.57297 18.903 8.79565L16.771 11.703C16.9161 11.9339 17 12.2072 17 12.5C17 13.3284 16.3284 14 15.5 14C14.7747 14 14.1697 13.4853 14.0303 12.8012L12.0087 11.5883C11.7356 11.9593 11.2958 12.2 10.7998 12.2C10.5876 12.2 10.3857 12.1559 10.2027 12.0765L7.90691 15.2906C7.7464 15.5153 7.43413 15.5673 7.20942 15.4068C6.98471 15.2463 6.93267 14.9341 7.09317 14.7094L9.46613 11.3872C9.35983 11.1813 9.2998 10.9477 9.2998 10.7C9.2998 9.87158 9.97138 9.20001 10.7998 9.20001C11.5928 9.20001 12.242 9.81534 12.2962 10.5946L14.2062 11.7406C14.4668 11.2974 14.9487 11 15.5 11C15.6705 11 15.8344 11.0285 15.9871 11.0809L18.0966 8.20429C18.2599 7.98161 18.5728 7.93347 18.7955 8.09677ZM15.5 12C15.2267 12 15.0047 12.2192 15.0001 12.4914L15.0001 12.509C15.0049 12.781 15.2269 13 15.5 13C15.7761 13 16 12.7761 16 12.5C16 12.2239 15.7761 12 15.5 12ZM11.2998 10.7C11.2998 10.9762 11.0759 11.2 10.7998 11.2C10.5237 11.2 10.2998 10.9762 10.2998 10.7C10.2998 10.4239 10.5237 10.2 10.7998 10.2C11.0759 10.2 11.2998 10.4239 11.2998 10.7Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoAnalysisLine);
export default Memo;
