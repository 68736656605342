import ReactInputMask from 'react-input-mask';

import { TextField } from 'components/@common/TextField';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const VatField = ({ value, onChange }: Props) => {
  return (
    /* @ts-ignore */
    <ReactInputMask
      alwaysShowMask
      value={value}
      onChange={(event) => onChange(event.target.value)}
      maskChar="_"
      mask="BE9999.999.999"
    >
      {/* @ts-ignore */}
      {(inputProps) => {
        return (
          <TextField
            {...inputProps}
            autoFocus
            handleChange={() => {}}
            label="registration.form.vat"
          />
        );
      }}
    </ReactInputMask>
  );
};

export default VatField;
