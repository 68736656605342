import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" role="img" {...props}>
    <rect width="48" height="48" rx="8" fill="#F5F8FF" />
    <path
      d="M18.7167 20.7248C22.1547 20.7248 23.6847 17.6108 23.6847 14.5148C23.6847 11.4188 22.1547 8.32279 18.7167 8.32279C15.2787 8.32279 13.7487 11.4188 13.7487 14.5148C13.7487 17.6108 15.2787 20.7248 18.7167 20.7248ZM18.7167 18.4568C17.0067 18.4568 16.3587 16.7288 16.3587 14.5148C16.3587 12.3008 17.0067 10.5908 18.7167 10.5908C20.4447 10.5908 21.0747 12.3008 21.0747 14.5148C21.0747 16.7288 20.4267 18.4568 18.7167 18.4568ZM29.3928 20.7248C32.2728 20.7248 34.1268 19.2668 34.1268 17.1968C34.1268 15.3968 32.5428 14.4428 31.3188 14.3168C32.6508 14.0828 33.9468 13.0928 33.9468 11.5808C33.9468 9.58279 32.2188 8.32279 29.4108 8.32279C27.3048 8.32279 25.7928 9.13279 24.8208 10.2308L26.0988 11.8328C26.9448 11.0228 27.9888 10.5908 29.1588 10.5908C30.4008 10.5908 31.3908 11.0588 31.3908 12.0128C31.3908 12.8948 30.4908 13.2908 29.1588 13.2908C28.7088 13.2908 27.8808 13.2908 27.6648 13.2728V15.5768C27.8448 15.5588 28.6548 15.5408 29.1588 15.5408C30.8328 15.5408 31.5708 15.9728 31.5708 16.9268C31.5708 17.8268 30.7608 18.4568 29.3028 18.4568C28.1328 18.4568 26.8188 17.9528 26.0088 17.0888L24.6588 18.7988C25.5408 19.8788 27.1968 20.7248 29.3928 20.7248Z"
      fill="#3349E8"
    />
    <path
      d="M18.5234 38.7248C21.9614 38.7248 23.4914 35.6108 23.4914 32.5148C23.4914 29.4188 21.9614 26.3228 18.5234 26.3228C15.0854 26.3228 13.5554 29.4188 13.5554 32.5148C13.5554 35.6108 15.0854 38.7248 18.5234 38.7248ZM18.5234 36.4568C16.8134 36.4568 16.1654 34.7288 16.1654 32.5148C16.1654 30.3008 16.8134 28.5908 18.5234 28.5908C20.2514 28.5908 20.8814 30.3008 20.8814 32.5148C20.8814 34.7288 20.2334 36.4568 18.5234 36.4568ZM29.6135 38.7248C32.5115 38.7248 34.4195 37.1048 34.4195 34.5668C34.4195 32.1368 32.6015 30.6788 30.5675 30.6788C29.4695 30.6788 28.4975 31.1108 27.9215 31.6868V28.7528H33.6095V26.5028H25.3655V33.3788L27.1475 33.8648C27.8675 33.1808 28.6775 32.8928 29.6675 32.8928C31.0355 32.8928 31.8275 33.6128 31.8275 34.6748C31.8275 35.6468 31.0175 36.4568 29.5955 36.4568C28.3355 36.4568 27.2195 35.9888 26.4095 35.1428L24.9875 36.9248C26.0135 38.0408 27.5255 38.7248 29.6135 38.7248Z"
      fill="#3349E8"
    />
  </svg>
);
const Memo = memo(SvgIcoUnlink);
export default Memo;
