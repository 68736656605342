import { Container, Typography } from '@mui/material';
import Link from 'next/link';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import { FooterItemStrapi, ItemLayoutStrapi, ServiceStrapi } from 'services/api/types';
import { theme } from 'theme';

import LanguageMenu from 'components/@common/SelectDropdown';
import { IcoFacebook, IcoLinkedIn, IcoTwitter, IcoYoutube } from 'components/@icons';

import {
  About,
  Address,
  Copyright,
  FooterContainer,
  FooterLink,
  FooterLinksContainer,
  LogoBelga,
  SocialMediaIcons,
} from '.';

interface FooterProps {
  footerData?: FooterItemStrapi;
}

const Footer = ({ footerData }: FooterProps) => {
  const services = useSelector<RootState, ServiceStrapi[]>((state) => state.serviceStore.services);

  const groupFooterItemByGroup = (mainFooter: ItemLayoutStrapi[]) => {
    const groupedItems: { [group: string]: ItemLayoutStrapi[] } = {};

    for (const item of mainFooter) {
      const group = item.group;
      if (groupedItems[group]) {
        groupedItems[group].push(item);
      } else {
        groupedItems[group] = [item];
      }
    }

    const result: { group: string; items: ItemLayoutStrapi[] }[] = Object.entries(groupedItems).map(
      ([group, items]) => ({
        group,
        items,
      }),
    );
    return result;
  };

  return (
    <Container sx={{ backgroundColor: theme.palette.global01[200], minWidth: '100%', margin: 0 }}>
      <FooterContainer component="footer">
        <About>
          <Link href="/">
            <LogoBelga />
          </Link>
          <Address>
            <FormattedMessage id="footer.address.line_one" />
            <br />
            <FormattedMessage id="footer.address.line_two" />
            <br />
            <FormattedMessage id="footer.address.line_three" />
          </Address>
          <LanguageMenu fullWidth={false} />
          <SocialMediaIcons>
            <Link target="_blank" href="https://twitter.com/BelgaNewsAgency">
              <IcoTwitter />
            </Link>
            <Link target="_blank" href="https://www.facebook.com/belganewsagency">
              <IcoFacebook />
            </Link>
            <Link target="_blank" href="https://www.linkedin.com/company/belga-news-agency/">
              <IcoLinkedIn />
            </Link>
            <Link target="_blank" href="https://www.youtube.com/user/BelgaNewsAgency">
              <IcoYoutube />
            </Link>
          </SocialMediaIcons>
        </About>

        <FooterLinksContainer>
          <Typography variant="footerHeading05">
            <FormattedMessage id="common.service" />
          </Typography>
          {services &&
            services.map((service, index) => (
              <FooterLink key={index} href={`/services/${service.attributes.url}`}>
                <Typography variant="paragraph02"> {service.attributes.title}</Typography>
              </FooterLink>
            ))}
        </FooterLinksContainer>
        {footerData?.mainFooter &&
          groupFooterItemByGroup(footerData?.mainFooter).map(
            (mainFooterGroup: { group: string; items: ItemLayoutStrapi[] }, index: number) => (
              <>
                <FooterLinksContainer key={index}>
                  <Typography variant="footerHeading05">{mainFooterGroup.group}</Typography>
                  {mainFooterGroup.items.map(
                    (mainFooterGroupItem: ItemLayoutStrapi, indexItem: number) => (
                      <FooterLink key={indexItem} href={mainFooterGroupItem.url}>
                        <Typography variant="paragraph02">{mainFooterGroupItem.label}</Typography>
                      </FooterLink>
                    ),
                  )}
                </FooterLinksContainer>
              </>
            ),
          )}
        <Copyright>
          <Link
            style={{
              pointerEvents: 'none',
              cursor: 'default',
            }}
            href=""
          >
            <Typography variant="caption01">
              <FormattedMessage id="footer.copyright" />
            </Typography>
          </Link>
          {footerData?.subFooter &&
            footerData?.subFooter.map((subFooterItem: ItemLayoutStrapi, index: number) => (
              <Link key={index} href={subFooterItem.url}>
                <Typography variant="caption01">{subFooterItem.label}</Typography>
              </Link>
            ))}
        </Copyright>
      </FooterContainer>
    </Container>
  );
};

export default Footer;
