import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" role="img" {...props}>
    <rect width="48" height="48" rx="8" fill="#F5F8FF" />
    <path
      d="M21.0342 20.5088V8.50279H18.8022L15.0222 12.3008L16.4802 13.8308L18.4782 11.7968V20.5088H21.0342ZM28.0206 20.7248C30.7566 20.7248 32.6286 18.9428 32.6286 16.6028C32.6286 14.0468 30.6846 12.6968 28.5246 12.6968C27.2106 12.6968 26.0046 13.4348 25.4466 14.2448C25.4286 14.1548 25.4286 14.0828 25.4286 13.9928C25.4286 12.0848 26.6706 10.5908 28.4346 10.5908C29.4966 10.5908 30.1806 10.9148 30.8286 11.5268L31.9806 9.56479C31.1166 8.80879 29.8926 8.32279 28.4346 8.32279C24.8886 8.32279 22.8366 10.8968 22.8366 14.5328C22.8366 17.9168 24.3666 20.7248 28.0206 20.7248ZM27.8766 18.4568C26.2026 18.4568 25.5546 17.1788 25.4466 16.0268C26.0226 15.3068 26.9046 14.8928 27.8046 14.8928C28.9386 14.8928 30.0186 15.4328 30.0186 16.6928C30.0186 17.5208 29.2626 18.4568 27.8766 18.4568Z"
      fill="#3349E8"
    />
    <path
      d="M18.6288 38.7248C22.0668 38.7248 23.5968 35.6108 23.5968 32.5148C23.5968 29.4188 22.0668 26.3228 18.6288 26.3228C15.1908 26.3228 13.6608 29.4188 13.6608 32.5148C13.6608 35.6108 15.1908 38.7248 18.6288 38.7248ZM18.6288 36.4568C16.9188 36.4568 16.2708 34.7288 16.2708 32.5148C16.2708 30.3008 16.9188 28.5908 18.6288 28.5908C20.3568 28.5908 20.9868 30.3008 20.9868 32.5148C20.9868 34.7288 20.3388 36.4568 18.6288 36.4568ZM32.9589 38.5088V36.0248H34.5069V33.7748H32.9589V26.5028H29.4309L24.6609 34.0088V36.0248H30.4029V38.5088H32.9589ZM30.4029 33.7748H27.1629L30.4029 28.7708V33.7748Z"
      fill="#3349E8"
    />
  </svg>
);
const Memo = memo(SvgIcoUnlink);
export default Memo;
