import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 10 10" width="1em" height="1em" role="img" {...props}>
    <title>{'ico_arrow-right'}</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.64645 0.646447C4.45118 0.841709 4.45118 1.15829 4.64645 1.35355L7.79289 4.5H0.5C0.223858 4.5 0 4.72386 0 5C0 5.27614 0.223858 5.5 0.5 5.5H7.79289L4.64645 8.64645C4.45118 8.84171 4.45118 9.15829 4.64645 9.35355C4.84171 9.54882 5.15829 9.54882 5.35355 9.35355L9.35355 5.35355C9.54882 5.15829 9.54882 4.84171 9.35355 4.64645L5.35355 0.646447C5.15829 0.451184 4.84171 0.451184 4.64645 0.646447Z"
    />
  </svg>
);
const Memo = memo(SvgIcoArrow);
export default Memo;
