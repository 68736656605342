import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoPressreview = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" role="img" {...props}>
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.629 11.1681C14.1826 10.9388 14.7758 10.8208 15.375 10.8208C15.9742 10.8208 16.5674 10.9388 17.121 11.1681C17.6745 11.3973 18.1775 11.7334 18.6012 12.1571C19.0248 12.5807 19.3609 13.0837 19.5902 13.6373C19.8195 14.1908 19.9375 14.7841 19.9375 15.3833C19.9375 15.9824 19.8195 16.5757 19.5902 17.1292C19.3609 17.6828 19.0248 18.1858 18.6012 18.6094C18.1775 19.0331 17.6745 19.3692 17.121 19.5985C16.5674 19.8277 15.9742 19.9458 15.375 19.9458C14.7758 19.9458 14.1826 19.8277 13.629 19.5985C13.0755 19.3692 12.5725 19.0331 12.1488 18.6094C11.7252 18.1858 11.3891 17.6828 11.1598 17.1292C10.9305 16.5757 10.8125 15.9824 10.8125 15.3833C10.8125 14.7841 10.9305 14.1908 11.1598 13.6373C11.3891 13.0837 11.7252 12.5807 12.1488 12.1571C12.5725 11.7334 13.0755 11.3973 13.629 11.1681ZM15.375 12.0708C14.94 12.0708 14.5093 12.1564 14.1074 12.3229C13.7055 12.4894 13.3403 12.7334 13.0327 13.041C12.7251 13.3486 12.4811 13.7137 12.3146 14.1156C12.1482 14.5175 12.0625 14.9483 12.0625 15.3833C12.0625 15.8183 12.1482 16.249 12.3146 16.6509C12.4811 17.0528 12.7251 17.418 13.0327 17.7255C13.3403 18.0331 13.7055 18.2771 14.1074 18.4436C14.5093 18.6101 14.94 18.6958 15.375 18.6958C15.81 18.6958 16.2407 18.6101 16.6426 18.4436C17.0445 18.2771 17.4097 18.0331 17.7173 17.7255C18.0249 17.418 18.2689 17.0528 18.4354 16.6509C18.6018 16.249 18.6875 15.8183 18.6875 15.3833C18.6875 14.9483 18.6018 14.5175 18.4354 14.1156C18.2689 13.7137 18.0249 13.3486 17.7173 13.041C17.4097 12.7334 17.0445 12.4894 16.6426 12.3229C16.2407 12.1564 15.81 12.0708 15.375 12.0708Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7172 17.7253C17.9613 17.4812 18.357 17.4812 18.6011 17.7253L20.8796 20.0038C21.1237 20.2479 21.1237 20.6436 20.8796 20.8877C20.6355 21.1318 20.2398 21.1318 19.9957 20.8877L17.7172 18.6092C17.4732 18.3651 17.4732 17.9694 17.7172 17.7253Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3125 10.8833C6.3125 10.5381 6.59232 10.2583 6.9375 10.2583H10.3125C10.6577 10.2583 10.9375 10.5381 10.9375 10.8833C10.9375 11.2284 10.6577 11.5083 10.3125 11.5083H6.9375C6.59232 11.5083 6.3125 11.2284 6.3125 10.8833Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3125 13.6958C6.3125 13.3506 6.59232 13.0708 6.9375 13.0708H8.625C8.97018 13.0708 9.25 13.3506 9.25 13.6958C9.25 14.0409 8.97018 14.3208 8.625 14.3208H6.9375C6.59232 14.3208 6.3125 14.0409 6.3125 13.6958Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3125 16.5083C6.3125 16.1631 6.59232 15.8833 6.9375 15.8833H8.625C8.97018 15.8833 9.25 16.1631 9.25 16.5083C9.25 16.8534 8.97018 17.1333 8.625 17.1333H6.9375C6.59232 17.1333 6.3125 16.8534 6.3125 16.5083Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3125 4.19576C9.73234 4.19576 9.17594 4.42622 8.7657 4.83646C8.35547 5.24669 8.125 5.80309 8.125 6.38326C8.125 6.72843 7.84518 7.00826 7.5 7.00826H4.6875C4.55489 7.00826 4.42771 7.06093 4.33395 7.1547C4.24018 7.24847 4.1875 7.37565 4.1875 7.50826V19.3208C4.1875 19.4534 4.24018 19.5805 4.33395 19.6743C4.42771 19.7681 4.55489 19.8208 4.6875 19.8208H10.3125C10.6577 19.8208 10.9375 20.1006 10.9375 20.4458C10.9375 20.7909 10.6577 21.0708 10.3125 21.0708H4.6875C4.22337 21.0708 3.77825 20.8864 3.45006 20.5582C3.12187 20.23 2.9375 19.7849 2.9375 19.3208V7.50826C2.9375 7.04413 3.12187 6.59901 3.45006 6.27082C3.77825 5.94263 4.22337 5.75826 4.6875 5.75826H6.93228C7.05789 5.07892 7.38646 4.44794 7.88182 3.95258C8.52648 3.30792 9.40082 2.94576 10.3125 2.94576C11.2242 2.94576 12.0985 3.30792 12.7432 3.95258C13.2385 4.44794 13.5671 5.07892 13.6927 5.75826H15.9375C16.4016 5.75826 16.8467 5.94263 17.1749 6.27082C17.5031 6.59901 17.6875 7.04413 17.6875 7.50826V9.19575C17.6875 9.54093 17.4077 9.82075 17.0625 9.82075C16.7173 9.82075 16.4375 9.54093 16.4375 9.19575V7.50826C16.4375 7.37565 16.3848 7.24847 16.2911 7.1547C16.1973 7.06093 16.0701 7.00826 15.9375 7.00826H13.125C12.7798 7.00826 12.5 6.72843 12.5 6.38326C12.5 5.80309 12.2695 5.24669 11.8593 4.83646C11.4491 4.42622 10.8927 4.19576 10.3125 4.19576Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3125 5.19576C10.0721 5.19576 9.84164 5.29123 9.67168 5.46119C9.50173 5.63115 9.40625 5.86165 9.40625 6.10201C9.40625 6.28125 9.4594 6.45646 9.55898 6.60549C9.65856 6.75452 9.8001 6.87068 9.96569 6.93927C10.1313 7.00786 10.3135 7.02581 10.4893 6.99084C10.6651 6.95587 10.8266 6.86956 10.9533 6.74282C11.0801 6.61608 11.1664 6.4546 11.2013 6.27881C11.2363 6.10301 11.2184 5.92079 11.1498 5.7552C11.0812 5.5896 10.965 5.44807 10.816 5.34849C10.667 5.24891 10.4917 5.19576 10.3125 5.19576Z"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width={18} height={18} transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgIcoPressreview);
export default Memo;
