import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" role="img" {...props}>
    <rect width="48" height="48" rx="8" fill="#F5F8FF" />
    <path
      d="M23.1154 20.5088V18.2588H18.2194C21.3334 15.9908 23.0254 14.2088 23.0254 12.1568C23.0254 9.76279 21.0094 8.32279 18.4354 8.32279C16.7614 8.32279 14.9794 8.93479 13.8094 10.2848L15.2674 11.9768C16.0774 11.1308 17.1214 10.5908 18.4894 10.5908C19.4794 10.5908 20.4334 11.0948 20.4334 12.1568C20.4334 13.6328 18.9934 14.8568 14.0794 18.4928V20.5088H23.1154ZM32.7744 20.5088V18.0248H34.3224V15.7748H32.7744V8.50279H29.2464L24.4764 16.0088V18.0248H30.2184V20.5088H32.7744ZM30.2184 15.7748H26.9784L30.2184 10.7708V15.7748Z"
      fill="#3349E8"
    />
    <path
      d="M18.5409 38.7248C21.9789 38.7248 23.5089 35.6108 23.5089 32.5148C23.5089 29.4188 21.9789 26.3228 18.5409 26.3228C15.1029 26.3228 13.5729 29.4188 13.5729 32.5148C13.5729 35.6108 15.1029 38.7248 18.5409 38.7248ZM18.5409 36.4568C16.8309 36.4568 16.1829 34.7288 16.1829 32.5148C16.1829 30.3008 16.8309 28.5908 18.5409 28.5908C20.2689 28.5908 20.8989 30.3008 20.8989 32.5148C20.8989 34.7288 20.2509 36.4568 18.5409 36.4568ZM29.577 38.7248C32.097 38.7248 34.401 37.6268 34.401 35.3948C34.401 33.9368 33.249 32.8208 31.827 32.3168C33.141 31.8488 34.203 30.9848 34.203 29.4908C34.203 27.2228 31.791 26.3228 29.577 26.3228C27.345 26.3228 24.951 27.2228 24.951 29.4908C24.951 30.9848 25.995 31.8488 27.327 32.3168C25.887 32.8208 24.753 33.9368 24.753 35.3948C24.753 37.6268 27.057 38.7248 29.577 38.7248ZM29.577 31.3088C28.839 31.1828 27.543 30.8048 27.543 29.9228C27.543 29.0768 28.389 28.5908 29.577 28.5908C30.747 28.5908 31.593 29.0768 31.593 29.9228C31.593 30.8048 30.315 31.1828 29.577 31.3088ZM29.577 36.4568C28.317 36.4568 27.345 35.8988 27.345 35.0348C27.345 34.0448 28.821 33.5768 29.577 33.4688C30.315 33.5768 31.809 34.0448 31.809 35.0348C31.809 35.8988 30.801 36.4568 29.577 36.4568Z"
      fill="#3349E8"
    />
  </svg>
);
const Memo = memo(SvgIcoUnlink);
export default Memo;
