import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBrand = (props: SVGProps<SVGSVGElement>) => (
  <svg id="Layer_1" viewBox="0 0 24 24" width="1em" height="1em" role="img" {...props}>
    <path d="M12,20.5a1.1,1.1,0,0,1-.8-.35L9.8,18.61,7.64,19a.93.93,0,0,1-.44,0,1.15,1.15,0,0,1-.41-.17,1.08,1.08,0,0,1-.31-.33,1.28,1.28,0,0,1-.14-.43l-.18-2-2-.83A.94.94,0,0,1,3.79,15a1,1,0,0,1-.28-.84,1,1,0,0,1,.15-.42L4.76,12l-1.1-1.77a1,1,0,0,1-.15-.43,1,1,0,0,1,0-.45A1,1,0,0,1,3.79,9a.94.94,0,0,1,.37-.25l2-.8.22-2.06a1.28,1.28,0,0,1,.14-.43,1.22,1.22,0,0,1,.31-.33,1.2,1.2,0,0,1,.42-.17,1,1,0,0,1,.43,0l2.08.46L11.2,3.85a1.08,1.08,0,0,1,.37-.26A1.38,1.38,0,0,1,12,3.5a1.11,1.11,0,0,1,.43.09,1.14,1.14,0,0,1,.36.26h0l1.4,1.54L16.36,5a1.29,1.29,0,0,1,.44,0,1,1,0,0,1,.41.18,1,1,0,0,1,.31.32,1.33,1.33,0,0,1,.14.44l.18,2,2,.83a1.07,1.07,0,0,1,.61.64,1,1,0,0,1,0,.45,1.06,1.06,0,0,1-.15.42L19.24,12l1.1,1.77a1.06,1.06,0,0,1,.15.42.86.86,0,0,1,0,.45.92.92,0,0,1-.23.39,1.05,1.05,0,0,1-.37.25l-2,.79-.22,2.07a1.36,1.36,0,0,1-.14.43,1,1,0,0,1-.31.32,1,1,0,0,1-.41.18,1.29,1.29,0,0,1-.44,0l-2.08-.46L12.8,20.15a1.1,1.1,0,0,1-.8.35ZM9.74,17.58a1.26,1.26,0,0,1,.33.05,1.12,1.12,0,0,1,.47.3l1.4,1.55h.12l1.4-1.54a1.12,1.12,0,0,1,.47-.3,1.07,1.07,0,0,1,.56,0l2.08.45L16.85,16a.94.94,0,0,1,.2-.53,1.15,1.15,0,0,1,.45-.34l2-.8L18.4,12.55a1,1,0,0,1,0-1.1l1.09-1.74-2-.85a1.15,1.15,0,0,1-.45-.34.94.94,0,0,1-.2-.53l-.19-2-2.17.42a1.07,1.07,0,0,1-.56,0,1.12,1.12,0,0,1-.47-.3l-1.4-1.54h0l-1.5,1.55a1.09,1.09,0,0,1-.48.3,1.13,1.13,0,0,1-.55,0L7.43,5.92,7.15,8a1,1,0,0,1-.2.53,1.08,1.08,0,0,1-.45.34l-2,.8L5.6,11.45a1,1,0,0,1,.16.54,1,1,0,0,1-.15.55l-1.1,1.75,2,.84a1.1,1.1,0,0,1,.45.35,1,1,0,0,1,.2.52l.19,2,2.17-.42A.85.85,0,0,1,9.74,17.58Z" />
  </svg>
);
const Memo = memo(SvgIcoBrand);
export default Memo;
