import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUnlink = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0332 6.93331V6C12.0332 5.72386 11.8093 5.5 11.5332 5.5C11.2571 5.5 11.0332 5.72386 11.0332 6V6.93331C11.0332 7.20945 11.2571 7.43331 11.5332 7.43331C11.8093 7.43331 12.0332 7.20945 12.0332 6.93331ZM7.44527 5.64645C7.64054 5.45118 7.95712 5.45118 8.15238 5.64645L10.019 7.51306C10.2143 7.70832 10.2143 8.0249 10.019 8.22016C9.82373 8.41543 9.50715 8.41543 9.31188 8.22016L7.44527 6.35355C7.25001 6.15829 7.25001 5.84171 7.44527 5.64645ZM7.79883 9.23322H8.73213C9.00828 9.23322 9.23213 9.45708 9.23213 9.73322C9.23213 10.0094 9.00828 10.2332 8.73213 10.2332H7.79883C7.52269 10.2332 7.29883 10.0094 7.29883 9.73322C7.29883 9.45708 7.52269 9.23322 7.79883 9.23322ZM12.8994 8.79991C12.8994 8.52377 13.1233 8.29991 13.3994 8.29991H16.1993C17.0745 8.29991 17.9139 8.64758 18.5327 9.26643C19.1516 9.88529 19.4992 10.7246 19.4992 11.5998C19.4992 12.475 19.1516 13.3144 18.5327 13.9332C17.9139 14.5521 17.0745 14.8997 16.1993 14.8997H13.3994C13.1233 14.8997 12.8994 14.6759 12.8994 14.3997C12.8994 14.1236 13.1233 13.8997 13.3994 13.8997H16.1993C16.8093 13.8997 17.3943 13.6574 17.8256 13.2261C18.2569 12.7948 18.4992 12.2098 18.4992 11.5998C18.4992 10.9899 18.2569 10.4049 17.8256 9.97354C17.3943 9.54222 16.8093 9.29991 16.1993 9.29991H13.3994C13.1233 9.29991 12.8994 9.07605 12.8994 8.79991ZM7.82372 12.0567C8.01898 11.8614 8.01898 11.5448 7.82372 11.3496C7.62846 11.1543 7.31187 11.1543 7.11661 11.3496L5.46653 12.9996C4.84767 13.6185 4.5 14.4579 4.5 15.3331C4.5 16.2082 4.84767 17.0476 5.46653 17.6665C6.08538 18.2853 6.92474 18.633 7.79993 18.633C8.67513 18.633 9.51448 18.2853 10.1333 17.6665L11.7834 16.0164C11.9787 15.8211 11.9787 15.5045 11.7834 15.3093C11.5882 15.114 11.2716 15.114 11.0763 15.3093L9.42623 16.9593C8.99491 17.3907 8.40991 17.633 7.79993 17.633C7.18995 17.633 6.60496 17.3907 6.17363 16.9593C5.74231 16.528 5.5 15.943 5.5 15.3331C5.5 14.7231 5.74231 14.1381 6.17363 13.7068L7.82372 12.0567Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoUnlink);
export default Memo;
