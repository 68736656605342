import { Box, Typography } from '@mui/material';
import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { languageCodeMap } from 'utils/localConvert';

import { useLocale } from 'hooks/useLocale';
import { DirectsService } from 'services/api';
import { DirectStrapi } from 'services/api/types';
import { theme } from 'theme';

import { IcoArrow, IcoShare } from 'components/@icons';

import { DropDownButton, DropDownItem, DropDownMenu } from '.';

interface Props {
  variant?: 'text' | 'default';
  fullWidth: boolean;
}

const DirectMenu = ({ variant = 'default', fullWidth = false }: Props) => {
  const { locale } = useLocale();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [directs, setDirects] = useState<DirectStrapi[] | []>([]);
  useEffect(() => {
    if (locale) {
      loadData(languageCodeMap[locale]);
    }
  }, [locale]);

  const loadData = async (locale: string) => {
    try {
      const response = await DirectsService.getAllDirects(`locale=${locale}`);
      if (response && response?.data) {
        setDirects(response?.data);
      } else {
        setDirects([]);
      }
    } catch (error) {
      Sentry.captureException(error);
      // Even if the API failed, we still go to the next step, but hide description in the Organisation step
    } finally {
    }
  };

  return (
    <>
      <DropDownButton $variant={variant} $fullWidth={fullWidth} onClick={handleClick}>
        <Box>
          {variant !== 'default' && (
            <IcoShare
              style={{
                fill: theme.palette.neutral01[300],
                width: '24px',
                height: '24px',
                marginRight: '5px',
                marginLeft: '-6px',
              }}
            />
          )}
          <Typography variant={variant === 'text' ? 'webParagraph02' : 'paragraph02'}>
            <FormattedMessage id="consume.direct.title" />
          </Typography>
        </Box>
        <IcoArrow
          style={{
            fill: theme.palette.global01[400],
            width: '24px',
            height: '24px',
            marginLeft: '7.5px',
          }}
        />
      </DropDownButton>
      <DropDownMenu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {directs &&
          directs?.map((direct, index) => (
            <Link
              href={`${direct.attributes.url}`}
              key={index}
              rel="noopener noreferrer"
              target="_blank"
            >
              <DropDownItem disableRipple disableTouchRipple>
                <Typography variant="paragraph02" fontWeight={'600'}>
                  {direct.attributes.title}
                </Typography>
              </DropDownItem>
            </Link>
          ))}
      </DropDownMenu>
    </>
  );
};

export default DirectMenu;
