import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoHashtag = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="#000000" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.52018 18.7341C6.44871 19.0008 6.607 19.275 6.87373 19.3465L7.69898 19.5676C7.96571 19.6391 8.23988 19.4808 8.31135 19.214L9.29306 15.5502H12.9364L12.0833 18.7341C12.0118 19.0008 12.1701 19.275 12.4368 19.3465L13.2621 19.5676C13.5288 19.6391 13.803 19.4808 13.8744 19.214L14.8561 15.5502H17.4805C17.7566 15.5502 17.9805 15.3264 17.9805 15.0502V14.1959C17.9805 13.9197 17.7566 13.6959 17.4805 13.6959H15.353L16.0983 10.9144H18.5C18.7762 10.9144 19 10.6905 19 10.4144V9.56001C19 9.28387 18.7762 9.06001 18.5 9.06001H16.5952L17.4551 5.85063C17.5266 5.58389 17.3683 5.30972 17.1016 5.23825L16.2764 5.01713C16.0096 4.94566 15.7355 5.10395 15.664 5.37068L14.6754 9.06001H11.0321L11.8921 5.85063C11.9635 5.58389 11.8052 5.30972 11.5385 5.23825L10.7133 5.01713C10.4465 4.94566 10.1724 5.10395 10.1009 5.37068L9.11234 9.06001H6.51958C6.24344 9.06001 6.01958 9.28387 6.01958 9.56001V10.4144C6.01958 10.6905 6.24344 10.9144 6.51958 10.9144H8.61547L7.87017 13.6959H5.5C5.22386 13.6959 5 13.9197 5 14.1959V15.0502C5 15.3264 5.22386 15.5502 5.5 15.5502H7.37329L6.52018 18.7341ZM9.78993 13.6959H13.4333L14.1786 10.9144H10.5352L9.78993 13.6959Z"
    />
  </svg>
);
const Memo = memo(SvgIcoHashtag);
export default Memo;
