import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoBold = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 5C6.5 4.72386 6.72386 4.5 7 4.5H8.86621H13.5333C13.9667 4.5 14.3958 4.58536 14.7962 4.7512C15.1966 4.91704 15.5604 5.16011 15.8668 5.46655C16.1732 5.77298 16.4163 6.13677 16.5821 6.53714C16.748 6.93752 16.8333 7.36664 16.8333 7.8C16.8333 8.23336 16.748 8.66248 16.5821 9.06286C16.4163 9.46323 16.1732 9.82702 15.8668 10.1335C15.676 10.3243 15.4629 10.4905 15.2326 10.6288C15.6557 10.8485 16.0467 11.1333 16.3901 11.4766C17.2715 12.358 17.7667 13.5535 17.7667 14.8C17.7667 16.0465 17.2715 17.242 16.3901 18.1234C15.5086 19.0048 14.3132 19.5 13.0667 19.5H8.86621H7C6.72386 19.5 6.5 19.2761 6.5 19C6.5 18.7239 6.72386 18.5 7 18.5H8.36621V10.6V5.5H7C6.72386 5.5 6.5 5.27614 6.5 5ZM9.36621 11.1V18.5H13.0667C14.048 18.5 14.9891 18.1102 15.683 17.4163C16.3768 16.7224 16.7667 15.7813 16.7667 14.8C16.7667 13.8187 16.3768 12.8776 15.683 12.1837C14.9891 11.4898 14.048 11.1 13.0667 11.1C13.0656 11.1 13.0646 11.1 13.0635 11.1H9.36621ZM13.0693 10.1C13.0683 10.1 13.0673 10.1 13.0662 10.1H9.36621V5.5H13.5333C13.8354 5.5 14.1345 5.55949 14.4135 5.67508C14.6926 5.79066 14.9461 5.96008 15.1597 6.17365C15.3733 6.38723 15.5427 6.64078 15.6583 6.91983C15.7738 7.19888 15.8333 7.49796 15.8333 7.8C15.8333 8.10204 15.7738 8.40112 15.6583 8.68017C15.5427 8.95922 15.3733 9.21277 15.1597 9.42635C14.9461 9.63992 14.6926 9.80934 14.4135 9.92492C14.1345 10.0405 13.8354 10.1 13.5333 10.1H13.0693Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoBold);
export default Memo;
