import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5848 6.03594C13.4914 5.56209 12.2889 5.39796 11.1086 5.56149C9.92829 5.72503 8.81567 6.20993 7.89237 6.96317C7.3434 7.41104 6.87393 7.94352 6.5 8.53781V6.00002C6.5 5.72388 6.27614 5.50002 6 5.50002C5.72386 5.50002 5.5 5.72388 5.5 6.00002V9.99914C5.5 10.2753 5.72386 10.4991 6 10.4991H6.33401C6.34126 10.4993 6.34849 10.4993 6.35571 10.4991H9.99912C10.2753 10.4991 10.4991 10.2753 10.4991 9.99914C10.4991 9.723 10.2753 9.49914 9.99912 9.49914H7.10274C7.44822 8.8222 7.93156 8.22178 8.52452 7.73803C9.30574 7.10068 10.2472 6.6904 11.2458 6.55203C12.2445 6.41366 13.262 6.55254 14.1871 6.95347C15.1122 7.3544 15.9092 8.00196 16.4911 8.82534C17.0729 9.64872 17.4172 10.6162 17.4862 11.6221C17.5553 12.6279 17.3465 13.6334 16.8826 14.5286C16.4187 15.4238 15.7177 16.1742 14.8561 16.6977C13.9944 17.2213 13.0055 17.498 11.9972 17.4974C11.7211 17.4972 11.4971 17.7209 11.497 17.9971C11.4968 18.2732 11.7205 18.4972 11.9967 18.4974C13.1882 18.4981 14.3571 18.1711 15.3754 17.5523C16.3937 16.9335 17.2222 16.0466 17.7705 14.9887C18.3187 13.9307 18.5655 12.7424 18.4839 11.5536C18.4023 10.3648 17.9954 9.22135 17.3077 8.24823C16.6201 7.2751 15.6781 6.50978 14.5848 6.03594Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoUndo);
export default Memo;
