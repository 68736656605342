import { DefaultSeo as DefaultNextSEO, LogoJsonLd, SocialProfileJsonLd } from 'next-seo';

import config from 'config';

import Logo from '/public/android-chrome-512x512.png';
import IntroImage from '/public/images/home_hero-image.png';

import {
  DefaultNextSEOStrapi,
  LogoJsonLdStrapi,
  SocialProfileJsonLdStrapi,
} from 'services/api/types';

interface DefaultSEOProps {
  DefaultNextSEOProps?: DefaultNextSEOStrapi;
  SocialProfileJsonLdProps?: SocialProfileJsonLdStrapi;
  LogoJsonLdProps?: LogoJsonLdStrapi;
}

const DefaultSEO = ({
  DefaultNextSEOProps,
  SocialProfileJsonLdProps,
  LogoJsonLdProps,
}: DefaultSEOProps) => {
  const FRONTEND_DOMAIN = process.env.NEXT_PUBLIC_FRONTEND_DOMAIN || 'https://belga.be/';
  const BACKEND_DOMAIN = process.env.NEXT_PUBLIC_API_BASE || 'https://belga.be/';

  return (
    <>
      <DefaultNextSEO
        dangerouslySetAllPagesToNoIndex={config.BUILD_ENV !== 'production'}
        dangerouslySetAllPagesToNoFollow={config.BUILD_ENV !== 'production'}
        titleTemplate={DefaultNextSEOProps?.titleTemplate || 'Belga - %s'}
        defaultTitle={DefaultNextSEOProps?.defaultTitle || 'Belga'}
        description={
          DefaultNextSEOProps?.description ||
          'Belga.be is a powerful news experience that enables you to consume, report & analyse news.'
        }
        canonical={DefaultNextSEOProps?.canonical || FRONTEND_DOMAIN}
        openGraph={{
          url: DefaultNextSEOProps?.openGraph?.url || FRONTEND_DOMAIN,
          title: DefaultNextSEOProps?.openGraph?.title || 'Belga.be',
          description:
            DefaultNextSEOProps?.openGraph?.description ||
            'Belga.be is a powerful news experience that enables you to consume, report & analyse news.',
          type: DefaultNextSEOProps?.openGraph?.type || 'website',
          images: [
            {
              url:
                DefaultNextSEOProps?.openGraph?.images?.data.attributes.url ||
                FRONTEND_DOMAIN + IntroImage.src,
              width:
                DefaultNextSEOProps?.openGraph?.images?.data.attributes.width || IntroImage.width,
              height:
                DefaultNextSEOProps?.openGraph?.images?.data.attributes.height || IntroImage.height,
              alt:
                DefaultNextSEOProps?.openGraph?.images?.data.attributes.alternativeText ||
                'Belga.be',
              type: DefaultNextSEOProps?.openGraph?.images?.data.attributes.mime || 'image/png',
            },
          ],
          siteName: DefaultNextSEOProps?.openGraph?.siteName || 'Belga.be',
        }}
        facebook={{
          appId: DefaultNextSEOProps?.facebook?.appId || '',
        }}
        twitter={{
          handle: DefaultNextSEOProps?.twitter?.handle || '@BelgaNewsAgency',
          site: DefaultNextSEOProps?.twitter?.site || '',
          cardType: DefaultNextSEOProps?.twitter?.cardType || 'summary_large_image',
        }}
      />
      <SocialProfileJsonLd
        type={SocialProfileJsonLdProps?.type || 'Organization'}
        name={SocialProfileJsonLdProps?.name || 'Belga.be'}
        url={SocialProfileJsonLdProps?.url || FRONTEND_DOMAIN}
        sameAs={
          SocialProfileJsonLdProps?.sameAs || [
            'https://www.facebook.com/belganewsagency',
            'https://www.youtube.com/user/BelgaNewsAgency',
            'https://www.linkedin.com/company/belga-news-agency/',
            'https://twitter.com/BelgaNewsAgency',
          ]
        }
      />
      <LogoJsonLd
        logo={
          LogoJsonLdProps?.logo.data.attributes.url
            ? BACKEND_DOMAIN + LogoJsonLdProps?.logo.data.attributes.url
            : BACKEND_DOMAIN + Logo.src
        }
        url={FRONTEND_DOMAIN}
      />
    </>
  );
};

export default DefaultSEO;
