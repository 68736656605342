import * as React from 'react';
import { memo, SVGProps } from 'react';

const SvgIcoRedo = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" role="img" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.41538 6.03594C10.5087 5.56209 11.7112 5.39796 12.8915 5.56149C14.0718 5.72503 15.1845 6.20993 16.1078 6.96317C16.6567 7.41104 17.1262 7.94352 17.5001 8.53781V6.00002C17.5001 5.72388 17.724 5.50002 18.0001 5.50002C18.2763 5.50002 18.5001 5.72388 18.5001 6.00002V9.99914C18.5001 10.2753 18.2763 10.4991 18.0001 10.4991H17.6661C17.6589 10.4993 17.6516 10.4993 17.6444 10.4991H14.001C13.7249 10.4991 13.501 10.2753 13.501 9.99914C13.501 9.723 13.7249 9.49914 14.001 9.49914H16.8974C16.5519 8.8222 16.0686 8.22178 15.4756 7.73803C14.6944 7.10068 13.753 6.6904 12.7543 6.55203C11.7556 6.41366 10.7381 6.55254 9.81304 6.95347C8.88797 7.3544 8.09093 8.00196 7.50907 8.82534C6.92722 9.64872 6.58294 10.6162 6.51389 11.6221C6.44484 12.6279 6.65366 13.6334 7.11754 14.5286C7.58141 15.4238 8.28248 16.1742 9.14408 16.6977C10.0057 17.2213 10.9947 17.498 12.0029 17.4974C12.279 17.4972 12.503 17.7209 12.5032 17.9971C12.5033 18.2732 12.2796 18.4972 12.0035 18.4974C10.8119 18.4981 9.64306 18.1711 8.62476 17.5523C7.60646 16.9335 6.7779 16.0466 6.22967 14.9887C5.68143 13.9307 5.43463 12.7424 5.51624 11.5536C5.59785 10.3648 6.00474 9.22135 6.69241 8.24823C7.38008 7.2751 8.32207 6.50978 9.41538 6.03594Z"
      fill="currentColor"
    />
  </svg>
);
const Memo = memo(SvgIcoRedo);
export default Memo;
