import { Box } from '@mui/material';
import { ReactNode } from 'react';

import useMedia from 'hooks/useMedia';
import { FooterItemStrapi, HeaderItemStrapi } from 'services/api/types';

import GlobalForm, { globalFormRef } from 'components/@common/GlobalForm/GlobalForm';
import Footer from 'components/@footer';
import { Navigation } from 'components/@navigation';
import NavigationMobile from 'components/@navigation/NavigationMobile';

interface Props {
  children: ReactNode;
  layout: {
    Header: HeaderItemStrapi[];
    Footer: FooterItemStrapi;
  };
}

const PageContainer = ({ children, layout }: Props) => {
  const { isMedium } = useMedia();
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box
        component="main"
        flex="1 auto"
        bgcolor="common.white"
        display="flex"
        flexDirection="column"
      >
        {isMedium ? (
          <NavigationMobile headerData={layout?.Header} />
        ) : (
          <Navigation headerData={layout?.Header} />
        )}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {children}
        </Box>
        <Footer footerData={layout?.Footer} />
        <GlobalForm ref={globalFormRef} />
      </Box>
    </Box>
  );
};

export default PageContainer;
